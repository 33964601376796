import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthCommonComponent } from './common/auth-common.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    AuthRoutingModule,
    IonicSelectableModule,
    SharedModule,
  ],
  providers: [WonderPush],
  declarations: [
    AuthCommonComponent,
    LoginComponent,
    SignUpComponent,
    PasswordRecoveryComponent,
  ],
})
export class AuthModule {}
