import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { eventFormData } from '../../events/events';
import {
  confirmDeletingPlanetItemModalData,
  confirmEditingPlanetModalData,
} from '../planet';
import { FormService } from '../../shared/components/form/form.service';
import { AuthService } from '../../firebase/auth.service';
import { PlanetService } from '../planet.service';
import { PlanetStore } from '../planet-store';
import {
  createToaster,
  positionType,
} from '../../shared/components/modals/toaster';
import { ImagesService } from 'src/app/services/images.service';
import { showLoading } from 'src/app/shared/components/modals/loading';

@Component({
  selector: 'app-edit-planet-item',
  templateUrl: './edit-planet-item.component.html',
  styleUrls: ['./edit-planet-item.component.scss'],
})
export class EditPlanetItemComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild('imageLoader') imageLoader;
  editPlanetItemForm: FormGroup;
  user;
  isOpen = false;
  id: string = this.activatedRouter.snapshot.params.id;
  addingBtnDisabled = true;
  imageField = eventFormData.filter(i => i.type === 'ARRAY');
  imagesArr: string[];
  form: FormGroup;
  statusDeleting = false;
  disabled = true;
  statusAdding = false;
  showForDelete = false;
  imagesControls = 1;
  newPlanetItem;
  showConfirmModal = false;
  confirmModalData = confirmEditingPlanetModalData;
  confirmForDelete = confirmDeletingPlanetItemModalData;
  currentImage;
  loadingImage;
  planetItem;
  loader = showLoading();
  private destroy$ = new Subject<void>();
  constructor(
    private formService: FormService,
    private imagesService: ImagesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private authService: AuthService,
    private planetService: PlanetService,
    private planetStore: PlanetStore
  ) {}

  ngOnInit() {
    this.getUser();
    this.statusListen();
    this.editPlanetItemForm = this.formBuilder.group({
      images: this.formBuilder.array([
        {
          0: this.formBuilder.group({
            image: ['', Validators.required],
          }),
        },
      ]),
      title: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.listenItemId();
    this.initialDataListener();

    this.planetStore
      .selectId()
      .pipe(takeUntil(this.destroy$))
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.planetStore.updateShowFormModal(false);
        this.planetService.isOpenForm.next(true);
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagesService.reset();
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  removeItem(e) {
    if (e) {
      this.planetStore.removeItem(this.id);
      this.planetStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClose.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['/planet/challenges']);
            }, 0);
          }
        });
    }
  }

  closeModal(e: boolean) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.editPlanetItemForm.controls['images'];
  }

  async updatePlanetItem() {
    this.planetStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.imagesService.imageUrls
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe(urls => {
        this.newPlanetItem = {
          ...this.planetItem,
          image: urls[0],
          title: this.editPlanetItemForm.get('title').value,
          description: this.editPlanetItemForm.get('description').value,
        };

        this.planetStore.updateProfile(this.newPlanetItem);

        this.planetStore
          .selectLoading()
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            if (data) {
              this.disabled = true;
              this.addingBtnDisabled = true;
              this.statusAdding = true;
            } else {
              this.isClose.next(true);
              createToaster(
                'Successfully updated!',
                'success',
                positionType.BOTTOM
              );
              this.disabled = false;
              this.addingBtnDisabled = false;
              this.statusAdding = false;
            }
          });
      });
  }

  listenItemId() {
    this.planetStore
      .selectId()
      .pipe(filter(id => !!id))
      .subscribe(id => {
        this.isClose.next(true);
        this.planetStore.updateShowFormModal(false);
        this.planetService.isOpenForm.next(true);
        setTimeout(() => {
          this.planetStore.patchState({ createdId: null });
          this.planetStore.getItemByByValue({ documentId: id });
          this.router.navigate(['/planet/challenges/', id]);
        }, 0);
      });
  }

  initialDataListener() {
    this.planetStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.editPlanetItemForm.patchValue(
          Object.assign({
            ...data,
          })
        );

        this.planetItem = data;
        this.imagesService.compressedImages.next([data.image]);
      });
    this.formService.currentPicture.subscribe(pic => {
      this.currentImage = pic;
    });
    this.formService.loadingImage.subscribe(loading => {
      this.loadingImage = loading;
      this.disabled = loading;
    });
    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
        this.addingBtnDisabled = !!(images.length < this.imagesControls);
      });
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();

    this.authService
      .getUserByUid(uid)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => (this.user = user));
  }

  statusListen() {
    this.planetStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        loading ? (await this.loader).present() : (await this.loader).dismiss();
      });
  }
}
