import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invitation',
})
export class InvitationPipe implements PipeTransform {
  transform(value: unknown, userId: string): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        const { invites, status } = item;
        return (
          invites &&
          invites.length &&
          invites.find(invitation => invitation.invited === userId) &&
          status === 'approved'
        );
      });
      return newValue;
    } else return value;
  }
}
