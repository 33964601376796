import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Navigation, SwiperOptions } from 'swiper';
import { StorageHelper } from 'src/app/firebase/helpers/storageHelper';
import { ProfileStore } from '../store/profile-store';
import { AuthService } from 'src/app/firebase/auth.service';
import { ImagesService } from 'src/app/services/images.service';
export interface IGallery {
  id: string;
  userId: string;
  images: { createdAt: Date; url: string }[];
}

const confirmForDelete = {
  title: 'Delete a Gallery picture?',
  icon: 'image',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: { title: 'NO, KEEP' },
};

const confirmForDiscard = {
  title: 'Discard your changes?',
  icon: 'image',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: { title: 'NO, GO BACK' },
};
@Component({
  selector: 'app-gallery-component',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() gallery$;
  @Input() forbidden: boolean;
  @Input() visibility: boolean;

  imageLoading: boolean = false;
  userGallery: IGallery;
  currentTime = new Date();
  currentUserId: string = this.activeRouter.snapshot.params.id;
  previewGallery: any[];
  isCreator;
  slideIndex = 0;
  showForDelete = false;
  showForDiscard = false;
  confirmForDelete = confirmForDelete;
  confirmForDiscard = confirmForDiscard;
  addImageToGalleryModal = false;
  isImageLoaded: boolean = false;
  previewImgs = [];

  isOpenSwiper = false;
  config: SwiperOptions;

  picsPerPage = 6;
  start = 0;
  end = 6;
  currentPage = 1;
  countPics = 4;
  fullSizeGallery = false;
  countPages: number;
  maxSize = false;

  isLastBtnDisabled = false;
  isNextBtnDisabled = false;
  isFirstBtnDisabled = true;
  isPrevBtnDisabled = true;
  authUserId;
  isOpenGalleryModal = false;
  imgResultMultiple: any[];
  currentFeedItem;
  imgs: any[] = [];
  addImageForm: FormGroup;
  private destroy$ = new Subject<void>();

  constructor(
    private storageHelper: StorageHelper,
    private profileStore: ProfileStore,
    private authService: AuthService,
    private activeRouter: ActivatedRoute,
    private imagesService: ImagesService,
    private fb: FormBuilder,
    private fireStore: AngularFirestore
  ) {
    this.addImageForm = this.fb.group({
      feedMessage: [''],
    });
  }

  ngOnInit() {
    const { uid } = this.authService.isUserAuth();
    this.authUserId = uid;
    this.listenStatusChanges();
    this.imagesService.addImageToGalleryModalIsOpen.subscribe(
      data => (this.addImageToGalleryModal = data)
    );
    // this.listenImages();
  }

  ngOnChanges() {
    this.gallery$.subscribe(gallery => {
      this.listenGalleryChanges(gallery);
      this.userGallery = gallery;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.profileStore.logOut();
    this.authUserId = '';
    this.userGallery = null;
  }

  upLoadImages(urls) {
    if (urls.length) {
      const newGallery = Object.assign({
        ...this.userGallery,
        images: [...urls, ...this.userGallery.images],
      });
      this.previewGallery = [...newGallery.images];
      this.profileStore.addToGallery(newGallery, 'gallery');
      this.imagesService.compressedImages.next([]);
      this.previewImgs = [];
      this.imagesService.imagesLoading.next(false);
      this.imagesService.imageUrls.next([]);
      this.imagesService.addImageToGalleryModalIsOpen.next(false);
    }
  }

  listenGalleryChanges(gallery) {
    if (gallery) {
      this.userGallery?.images?.sort(
        (image1: any, image2: any) =>
          image2.createdAt.seconds - image1.createdAt.seconds
      );

      this.fullSizeGallery = gallery.images.length > 4 && this.countPics > 4;
      this.isCreator = this.authUserId === gallery.userId;

      this.previewGallery = [...gallery.images];
    }
  }

  toggleGallery(i: number) {
    const currentIndex = i;
    this.slideIndex = currentIndex;
    this.isOpenSwiper = true;
    this.config = {
      modules: [Navigation],
      loop: true,
      navigation: true,
      initialSlide: currentIndex,
      on: {
        activeIndexChange: function (swiper) {
          this.slideIndex = swiper.realIndex;
        }.bind(this),
      },
    };
  }
  openSwiper(e: boolean) {
    e ? (this.isOpenSwiper = true) : (this.isOpenSwiper = false);
  }

  removeImg() {
    const images = [...this.userGallery.images];
    let currentPhoto = images[this.slideIndex];
    images.splice(this.slideIndex, 1);
    const newGallery = Object.assign({
      ...this.userGallery,
      images,
    });
    this.isOpenSwiper = false;
    this.profileStore.updateGallery(newGallery);

    this.fireStore
      .collection('activityFeed', ref =>
        ref
          .where('feedType', '==', 'userPhoto')
          .where('userId', '==', this.currentUserId)
      )
      .get()
      .subscribe(res => {
        res.docs.forEach(item => {
          (item.data() as any).images.forEach(image => {
            if (image.url === currentPhoto.url) {
              this.currentFeedItem = item.data() as any;
              let index = this.currentFeedItem.images.findIndex(
                item => item.url === currentPhoto.url
              );
              if (this.currentFeedItem.images.length === 1) {
                this.fireStore
                  .collection('activityFeed')
                  .doc(this.currentFeedItem.feedItemId)
                  .delete()
                  .finally(() => {
                    console.log('Successfully deleted');
                  });
              } else {
                this.currentFeedItem.images.splice(index, 1);
                this.fireStore
                  .collection('activityFeed')
                  .doc(this.currentFeedItem.feedItemId)
                  .update(this.currentFeedItem)
                  .finally(() => {
                    console.log('Successfully updated');
                  });
              }
            }
          });
        });
      });

    this.showForDelete = false;
  }

  showConfirmForDelete(e?) {
    this.showForDelete = true;
  }

  listenStatusChanges() {
    // this.profileStore.selectLoading().subscribe(loading => {
    //   this.imageLoading = loading;
    // });
  }

  closeGallery() {
    this.isOpenGalleryModal = false;
  }

  showMoreGallery() {
    const imageCount = this.userGallery.images.length;
    this.fullSizeGallery = this.countPics === imageCount;
    this.fullSizeGallery ? (this.countPics = 4) : (this.countPics = imageCount);
    this.fullSizeGallery = this.countPics === imageCount;
  }

  discardChanges() {
    this.showForDelete = false;
  }

  // discardGalleryChanges() {
  //   this.showForDiscard = false;
  //   this.addImageToGalleryModal = false;
  //   this.imgs = [];
  //   this.imagesService.compressedImages.next([]);
  //   this.previewImgs = [];
  // }

  onImageLoad() {
    this.isImageLoaded = true;
  }

  closeModal($event: boolean) {
    this.showForDiscard = false;
  }
}
