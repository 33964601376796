// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../../node_modules/flag-icons/css/flag-icons.min.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":host .fi {\n  margin-right: 3px;\n}\n:host .pods-icon {\n  padding: 0 2px;\n}\n:host ion-searchbar,\n:host .sc-ion-searchbar-ios-h {\n  --border-radius: 18px;\n  --box-shadow: none;\n  padding: 8px;\n}\n:host ion-list {\n  padding-top: 3px;\n}", "",{"version":3,"sources":["webpack://./src/app/merfolk/pods/pods/pods.component.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;;EAEE,qBAAA;EACA,kBAAA;EACA,YAAA;AAHJ;AAME;EACE,gBAAA;AAJJ","sourcesContent":["@import \"flag-icons/css/flag-icons.min.css\";\n\n:host {\n  .fi {\n    margin-right: 3px;\n  }\n\n  .pods-icon {\n    padding: 0 2px;\n  }\n\n  ion-searchbar,\n  .sc-ion-searchbar-ios-h {\n    --border-radius: 18px;\n    --box-shadow: none;\n    padding: 8px\n  }\n\n  ion-list {\n    padding-top: 3px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
