import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EventsService } from '../events.service';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { confirmDeletingModalData } from '../events';
import { EventsStore } from '../events-store';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnDestroy {
  @Input() items;
  @Input() feedItemId: string;
  @Input() divider: { title: string; icon: string; color: string };
  @Input() currentUserId;
  @Input() isAdmin;
  @Input() transparent: boolean;
  @Input() status: string;

  showStatusModal: boolean = false;
  itemStatus;

  showConfirmModal;
  confirmDeletingModalData = confirmDeletingModalData;
  disabled = false;
  loading = false;
  selectedEvent;

  private destroy$ = new Subject<void>();

  constructor(
    private eventsService: EventsService,
    private router: Router,
    private store: EventsStore
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  openConfirmModal(item, e) {
    e.stopPropagation();
    this.showConfirmModal = true;
    this.selectedEvent = item;
  }

  closeModal(e) {
    this.showConfirmModal = false;
  }

  openItem(job, currentUserId: string, isAdmin: boolean) {
    if (!this.eventSuspendedCheck(job, currentUserId, isAdmin)) {
      if (this.feedItemId) {
        this.router.navigate(['/events/', this.feedItemId]);
      } else {
        this.router.navigate(['/events/', job.id]);
      }
    }
  }

  removeItem() {
    this.disabled = true;
    this.loading = true;
    this.store.removeItem(this.selectedEvent.id);
    this.store
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.showConfirmModal = false;
        if (data) {
          this.loading = false;
          this.disabled = false;
          this.showConfirmModal = false;
        } else {
          createToaster(
            'Successfully deleted!',
            'success',
            positionType.BOTTOM
          );
        }
      });
  }

  eventSuspendedCheck(event, currentUserId, isAdmin) {
    return isAdmin
      ? false
      : this.eventsService.eventSuspendedCheck(event, currentUserId);
  }
}
