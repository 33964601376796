import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  type = 'password';
  iconName = 'eye';

  private errorLoginMsg = new BehaviorSubject<string | null>('');
  private loadSpinner = new BehaviorSubject(false);
  loadSpinner$ = this.loadSpinner.asObservable();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      rememberMe: [false],
    });
  }

  async login() {
    this.loadSpinner.next(true);
    this.errorLoginMsg.next(null);
    this.loginForm.markAsPristine();
    try {
      await this.authService.signIn(this.loginForm.value);
      this.router.navigateByUrl('/mood', { replaceUrl: true });
    } catch (error) {
      if (error === 'auth/network-request-failed') {
        this.createToaster('Internal server error');
      } else {
        this.createToaster(
          `Invalid credentials. New here? <span class='message-span'>Sign up</span> now!`
        );
      }
    } finally {
      this.loadSpinner.next(false);
    }
  }

  changeType() {
    this.type = this.type === 'password' ? 'text' : 'password';
    this.iconName = this.iconName === 'eye' ? 'eye-off' : 'eye';
  }

  private async createToaster(message: string) {
    const t = await this.toastController.create({
      message,
      color: 'danger',
      duration: 5000,
      position: 'top',
    });
    (t.shadowRoot?.querySelector('.message-span') as HTMLElement).style.cursor =
      'pointer';
    (
      t.shadowRoot?.querySelector('.message-span') as HTMLElement
    ).style.textDecoration = 'underLine';
    (
      t.shadowRoot?.querySelector('.message-span') as HTMLElement
    ).addEventListener('click', () => {
      this.router.navigateByUrl('/auth/sign-up');
      t.dismiss();
    });
    t.present();
  }
}
