import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { IonModal, Platform } from '@ionic/angular';
import { BehaviorSubject, finalize, interval, take } from 'rxjs';
import { travelingQuotes } from './traveling-quotes';
import { AdmobService } from 'src/app/shared/adv/admob.service';

@Component({
  selector: 'app-traveling-modal',
  templateUrl: './traveling-modal.component.html',
  styleUrls: ['./traveling-modal.component.scss'],
})
export class TravelingModalComponent implements OnDestroy {
  @ViewChild('modal') modal: IonModal;
  @Input() showTravelingModal: boolean;
  @Input() routerTitle: string;
  @Input() routerLink: string[] | null;
  @Input() background: string;
  @Input() random: number;
  @Output() ok: EventEmitter<boolean> = new EventEmitter(false);

  green = 'green';
  timer;
  msg: string = null;
  showBanner = false;
  loading = new BehaviorSubject<boolean>(true);
  isCanClose = false;
  travelingQuotes = travelingQuotes;
  // isMobile = this.admobService.isMobile;
  isMobileWeb = this.admobService.isMobileWeb;

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;

  constructor(
    private router: Router,
    private admobService: AdmobService,
    private platform: Platform,
    private location: Location
  ) {}

  handleOk() {
    this.ok.next(true);
    this.timer = null;
  }

  ionViewWillEnter() {
    this.admobService.ionViewWillEnter();
  }

  async ionViewWillLeave() {
    await this.admobService.ionViewWillLeave();
  }

  startTimer() {
    this.isCanClose = false;
    if (this.showTravelingModal && !this.timer) {
      this.loading.next(true);
      this.timer = interval(1000)
        .pipe(
          take(5),
          finalize(async () => {
            if (this.isMobile) {
              if (!this.location.path().includes('media')) {
                await this.admobService.removeBanner();
              }
            }
            this.ok.next(true);
            this.showTravelingModal = false;
            this.showBanner = false;
            this.msg = null;
            this.timer = null;
            this.isCanClose = true;
            this.routerLink
              ? this.router.navigate(this.routerLink)
              : window.open(
                  'https://discord.com/channels/920296167177355284',
                  '_blank'
                );
          })
        )
        .subscribe(async sec => {
          if (!this.showBanner && this.isMobile) {
            await this.admobService.showTravelingAd();
            this.showBanner = true;
            this.loading.next(false);
            if (this.admobService.bannerLoaded) {
              this.loading.next(false);
            }
          }
          if (!this.showBanner && !this.isMobile) {
            this.loading.next(false);
            this.showBanner = true;
          }

          this.msg = sec === 3 ? `${4 - sec} second` : `${4 - sec} seconds`;
        });
    }
  }

  ngOnDestroy() {
    this.loading.next(true);
  }
}
