import { Injectable } from '@angular/core';
import { CrudHelper } from '../firebase/helpers/crudHelper';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private crudHelper: CrudHelper) {}

  getFlaggedContent() {
    return this.crudHelper.getCollectionItems({
      collectionName: 'flaggedContent',
      queryFn: null,
    });
  }
}
