import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../shared/component-store/common-service';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { AuthService } from '../firebase/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityFeedServiceService extends CommonService {
  isOpenForm = new BehaviorSubject(false);

  userId: string;

  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private authService: AuthService
  ) {
    super(firebaseHelperService);
    this.getUser();
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { category, location, myFollowedMersOnly, mers, dateRange } =
        filter;

      if (location?.length) {
        query = query.where('location.region', 'in', location);
      }

      if (mers || myFollowedMersOnly) {
        query = query.where('userId', 'in', mers);
      }

      if (category) {
        const { length } = category;
        if (length > 1) {
          query = query.where('feedType', 'in', category);
        } else {
          if (category.includes('Pods')) {
            query = query.where('feedType', '==', 'pods');
          }

          if (category.includes('Events')) {
            query = query.where('feedType', '==', 'events');
          }

          if (category.includes('Pools')) {
            query = query.where('feedType', '==', 'pools');
          }

          if (category.includes('Second Hand')) {
            query = query.where('feedType', '==', 'secondHand');
          }

          if (category.includes('Service Seeker')) {
            query = query.where('feedType', '==', 'jobs');
          }

          if (category.includes('Service Offer')) {
            query = query.where('feedType', '==', 'services');
          }

          if (category.includes('Other')) {
            query = query.where('feedType', 'in', [
              'userPhoto',
              'newUser',
              'planetChallenge',
              'planetExperience',
            ]);
          }
        }
      }

      if (dateRange) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let startDate: Date;
        let endDate: Date;

        if (dateRange.includes('today')) {
          startDate = new Date(today);
          endDate = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        } else if (dateRange.includes('last 7 days')) {
          startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
          endDate = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        } else if (dateRange.includes('last 14 days')) {
          startDate = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
          endDate = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        } else if (dateRange.includes('month till date')) {
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        } else if (dateRange.includes('last month')) {
          startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          endDate = new Date(today.getFullYear(), today.getMonth(), 1);
        }
        query = query
          .where('createdAt', '>=', startDate)
          .where('createdAt', '<', endDate);
      }

      query = query.orderBy('createdAt', 'desc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const result =
      filter === false ||
      (!filter.dateRange &&
        (filter.category === null || !filter.category?.length) &&
        (filter.location === null || !filter.location?.length) &&
        !filter.myFollowedMersOnly);
    return result ? 'dark' : 'success';
  }

  private getUser() {
    const { uid } = this.authService.isUserAuth();
    this.authService
      .getUserByUid(uid)
      .subscribe(user => (this.userId = user.id));
  }
}
