import { Component, Input, OnInit } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../firebase/auth.service';

@Component({
  selector: 'app-controls-btn',
  templateUrl: './controls-btn.component.html',
  styleUrls: ['./controls-btn.component.scss'],
})
export class ControlsBtnComponent implements OnInit {
  @Input() collectionName: string;
  @Input() item;

  isRejectMsgModalOpen = false;
  properties;
  constructor(
    private authService: AuthService,
    private crudHelper: CrudHelper
  ) {}

  ngOnInit(): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
  }

  trackEvent(event, parameter, collectionName) {
    if (parameter === 'content-type') {
      this.properties = {
        'content-type': collectionName,
      };
    }

    mixpanel.track(event, this.properties);
  }

  rejectRequestHandler(event, collectionName, item) {
    const newItem = Object.assign({
      ...item,
      status: 'rejected',
      flaggedMsg: event,
    });
    this.crudHelper
      .updateItem({ data: newItem, id: newItem.id, collectionName })
      .subscribe(() => {
        createToaster('Successfully rejected', 'success', positionType.BOTTOM);
        this.trackEvent('Content Flagged', 'content-type', collectionName);
      });
  }

  acceptRequestHandler(collectionName, item) {
    const newItem = Object.assign({
      ...item,
      status: 'approved',
      flaggedMsg: null,
    });
    this.crudHelper
      .updateItem({ data: newItem, id: newItem.id, collectionName })
      .subscribe(() => {
        createToaster('Successfully approved', 'success', positionType.BOTTOM);
        this.trackEvent('Content Approved', 'content-type', collectionName);
      });
  }

  rejectModalOpen() {
    this.isRejectMsgModalOpen = true;
  }

  rejectModalClose() {
    this.isRejectMsgModalOpen = false;
  }
}
