import { Injectable } from '@angular/core';
import { CommonComponentStore } from '../../shared/component-store/common-component-store';
import { IPool } from 'src/app/shared/models/item.models';
import { PoolsService } from './pools.service';

@Injectable({ providedIn: 'root' })
export class PoolsStore extends CommonComponentStore<IPool> {
  constructor(poolsService: PoolsService) {
    super(poolsService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      showFormModal: false,
      showPoolPendingModal: false,
      collectionName: 'pools',
    });
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPoolPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPoolPendingModal);
  }

  updateShowPoolPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPoolPendingModal: value });
  }
}
