import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { BehaviorSubject, Observable, combineLatest, filter, map } from 'rxjs';
import { AuthService } from 'src/app/firebase/auth.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { CommonService } from 'src/app/shared/component-store/common-service';
import { IPod } from 'src/app/shared/models/item.models';

@Injectable({
  providedIn: 'root',
})
export class PodService extends CommonService {
  isOpenForm = new BehaviorSubject(false);
  userId;

  podsSuggestions$ = new BehaviorSubject<IPod[]>([]);

  constructor(
    private firebaseHelperService: CrudHelper,
    private authService: AuthService
  ) {
    super(firebaseHelperService);
    // this.getUser();
  }

  isOpenAddingModal = new BehaviorSubject<boolean>(false);
  isOpenEditingModal = new BehaviorSubject<boolean>(false);
  showConfirmModal = new BehaviorSubject<boolean>(false);
  showSuccessModal$ = new BehaviorSubject<boolean>(false);
  showQuestionsModal = new BehaviorSubject<boolean>(false);

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { country, status, creator, myContent, approval } = filter;
      if (country && country.length > 0) {
        query = query.where('country', 'in', country);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (approval) {
        query = query.where('joinMechanism', '==', 'automatic');
      }

      if (!status && !creator && !myContent) {
        query = query.where('status', '==', 'approved');
      }

      if (creator || myContent) {
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
        ]);
      }

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const result =
      filter === false ||
      (!filter.approval &&
        (filter.country === null || !filter.country?.length) &&
        !filter.myContent);
    return !result;
  }

  getLastItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      query = query
        .where('creatorId', '==', filter.creator)
        .orderBy('name')
        .limitToLast(10);
      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getFirstPageItems(
    limit: number,
    orderBy: string,
    collectionName: string,
    creator: string
  ) {
    const queryFn = ref => {
      let query = ref;

      query = query
        .where('creator.id', '==', creator)
        .limit(limit)
        .orderBy(orderBy);

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getMyItems(collectionName, creator) {
    const queryFn = ref => {
      let query = ref;

      query = query.where('creator.id', '==', creator);

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getPrevPage(collectionName, userId, limit, prevFirstInResponse, forbidden) {
    return this.firebaseHelperService.getPrevPaginatedItems({
      collectionName,
      userId,
      limit,
      prevFirstInResponse,
      forbidden,
    });
  }

  getNextPage(
    limit: number,
    orderBy: string,
    lastInResponse,
    collectionName: string,
    creator: string,
    forbidden
  ) {
    return this.firebaseHelperService.getNextPaginatedItems({
      collectionName,
      userId: creator,
      limit,
      lastInResponse,
      forbidden,
    });
  }

  getItemsCount(
    collectionName: any,
    userId: string,
    forbidden: boolean
  ): Observable<number> {
    return this.firebaseHelperService.getCountItems({
      collectionName,
      userId,
      forbidden,
    });
  }

  async givePermission(podId) {
    const functions = getFunctions();
    const givePermissionToDiscord = httpsCallable(
      functions,
      'givePermissionToDiscord'
    );
    await givePermissionToDiscord({ podId });
  }

  getPodInvitationNotifications(id) {
    const notifications = this.firebaseHelperService.getById({
      id,
      collection: 'notifications',
    });
    return notifications;
  }

  sendInvitationToPod(pods: IPod[], userId: string, currentUser: string) {
    pods.map(pod => (pod.invites ? null : (pod.invites = [])));

    const obsrvs$ = pods.map(pod => {
      if (pod.denied?.includes(userId)) {
        const denied = pod.denied.filter(id => id !== userId);
        return this.updateItemById(
          'pods',
          {
            denied,
            invites: [
              ...pod.invites,
              { inviter: currentUser, invited: userId },
            ],
          },
          pod.id
        );
      } else {
        return this.updateItemById(
          'pods',
          {
            invites: [
              ...pod.invites,
              { inviter: currentUser, invited: userId },
            ],
          },
          pod.id
        );
      }
    });

    return combineLatest(obsrvs$);
  }

  inviteToPod(userId) {
    const { uid } = this.authService.isUserAuth();
    return this.getItems({
      collectionName: 'pods',
      filter: { creator: uid },
    }).pipe(
      filter(items => !!items),
      map((items: any) =>
        items.filter(
          item =>
            !!item &&
            item.participants.includes(uid) &&
            !item.participants.includes(userId) &&
            item.status === 'approved' &&
            !item.invites?.find(item => item.invited === userId) &&
            !item.memberRequests?.find(item => item.id === userId)
        )
      )
    );
  }

  userPodsFilter(pods, userId) {
    const newPods = pods
      ?.filter(item =>
        item.status === 'pending' || item.status === 'rejected'
          ? item.creator.id === userId
          : item
      )
      .filter(item =>
        item.status === 'approved'
          ? item.participants.includes(userId) ||
            item.invites?.find(item => item.invited === userId) ||
            item.memberRequests?.find(request => request.id === userId) ||
            item.denied?.find(item => item === userId)
          : item
      );

    return newPods;
  }

  userProfilePodsFilter(pods, userId) {
    const newPods = pods?.filter(item => {
      if (!item) {
        return false;
      }
      if (item.status === 'pending' || item.status === 'rejected') {
        return item.creator.id === userId;
      }
      if (item.status === 'approved') {
        return item.participants.includes(userId);
      }
    });
    return newPods;
  }

  deleteInvitation(item) {
    return this.updateItemById('pods', item, item.id);
  }
}
