export enum positionType {
  TOP = 'top',
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
}

import { ToastController } from '@ionic/angular';

export const createToaster = async (
  message: string,
  color: string,
  position: positionType
) => {
  const controller = new ToastController();
  const t = await controller.create({
    message,
    color: color,
    position: position,
    duration: 3000,
    buttons: [
      {
        side: 'start',
        icon: 'close',
        role: 'cancel',
      },
    ],
  });
  t.present();
};
