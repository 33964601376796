import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Observable } from 'rxjs';
import { PodService } from '../../pods/pods.service';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-community-item',
  templateUrl: './community-item.component.html',
  styleUrls: ['./community-item.component.scss'],
})
export class CommunityItemComponent {
  @Input() user;
  @Input() currentUser;
  @Input() currentPage;
  @Output() functionEvent = new EventEmitter<Function>();
  @Input() userPods;
  @Input() isMap: boolean;

  showInvitationModal = false;
  loading;

  podsSuggestions$: Observable<any>;
  constructor(
    private router: Router,
    private podService: PodService,
    private mapService: MapService
  ) {}

  goToProfile(route) {
    this.router.navigate(route);
    if (this.isMap) this.mapService.modalIsOpen.next(false);
  }

  close(e: Event) {
    e.stopPropagation();
    this.mapService.modalIsOpen.next(false);
  }

  async follow(e) {
    e.stopPropagation();
    this.loading = true;
    const functions = getFunctions();
    const follow = httpsCallable(functions, 'follow');
    try {
      await follow({ id: this.user?.uid });
    } catch (err) {
      console.log(err);
    } finally {
      this.functionEvent.emit();
      this.loading = false;
    }
  }

  async unfollow(e) {
    e.stopPropagation();
    this.loading = true;
    const functions = getFunctions();
    const unfollow = httpsCallable(functions, 'unfollow');
    try {
      await unfollow({ id: this.user?.uid });
    } catch (err) {
      console.log(err);
    } finally {
      this.functionEvent.emit();
      this.loading = false;
    }
  }

  inviteToPod(e) {
    e.stopPropagation();
    this.podsSuggestions$ = this.podService.inviteToPod(this.user.uid);
    this.showInvitationModal = true;
  }

  async addToBlockList(e) {
    e.stopPropagation();
    this.loading = true;
    const functions = getFunctions();
    const addToBlockList = httpsCallable(functions, 'addToBlockList');
    try {
      await addToBlockList({ id: this.user?.uid });
    } catch (err) {
      console.log(err);
    } finally {
      this.functionEvent.emit();
      this.loading = false;
    }
  }
}
