import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { filter, Subject, takeUntil } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Navigation, SwiperOptions } from 'swiper';
import { IToolbarData } from 'src/app/shared/models/components-data.models';
import { ItemStatus } from 'src/app/shared/models/item.models';
import { ServicesStore } from '../services.store';
import { successServicesModalData } from '../../jobs/jobs';
import { environment } from 'src/environments/environment';
import { IUser } from 'src/app/auth/auth.models';
import { UserService } from 'src/app/auth/user.service';

const TOOLBAR_DATA: IToolbarData = {
  arrowRouter: '/bazaar/job&services/services',
  btnSlot: 'end',
  title: 'Service Seekers',
};

const TOOLBAR_SWIPER: IToolbarData = {
  arrow: true,
  btnSlot: 'end',
  title: 'Service Seekers',
};

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss'],
})
export class ServicesDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('col') col: ElementRef;

  TOOLBAR_DATA = TOOLBAR_DATA;
  TOOLBAR_SWIPER = TOOLBAR_SWIPER;
  config: SwiperOptions;

  serviceItem;
  currentUserID;
  creator;
  currentUser: IUser;
  isAdmin: boolean;

  status: ItemStatus;
  duration;
  currentTime: Date = new Date();
  itemImage: string;

  flagData;
  isContentFlaggedModal;
  isOpenFlagModal;

  isOpen = false;
  showSuccessModal = false;
  successModalData = successServicesModalData;
  isOpenFormModal = false;
  infoSnapshot;
  userInfo;
  showStatusModal: boolean = false;
  itemStatus;
  galleryUrls: string;
  isOpenGallery = false;

  discordServerInviteLink = environment.discordServerInviteLink;
  itemId = this.router.snapshot.params.id;
  private destroy$ = new Subject<void>();

  constructor(
    private servicesStore: ServicesStore,
    private router: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUser();
    const app: HTMLElement = document.querySelector('ion-router-outlet');
    app.style.marginBottom = '0px';
    this.servicesStore.getItemByByValue({
      documentId: this.router.snapshot.params.id,
    });
    this.listenJobsDetails();

    this.servicesStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });

    this.servicesStore.selectShowPendingModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.infoSnapshot) {
      this.infoSnapshot.unsubscribe();
    }
  }

  handleOk(e: boolean) {
    e ? this.servicesStore.updateShowPendingModal(false) : null;
  }

  handleCancel(e: boolean) {
    e ? this.servicesStore.updateShowPendingModal(false) : null;
  }

  openModal() {
    this.servicesStore.updateShowFormModal(true);
  }

  closeModal(e: boolean) {
    e ? this.servicesStore.updateShowFormModal(false) : null;
  }

  openStatusModal() {
    this.showStatusModal = !this.showStatusModal;
  }

  async flagContent(slide) {
    slide.type = 'services';

    this.flagData = slide;

    const functions = getFunctions();
    const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
    const res = await isContentFlagged({
      itemId: slide.id,
      url: slide.images[0],
      type: this.flagData.type,
    });
    if (res.data === true) {
      this.isContentFlaggedModal = true;
    } else {
      this.isOpenFlagModal = true;
    }
  }

  private listenJobsDetails() {
    this.servicesStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(item => {
          this.serviceItem = item;

          this.itemImage = this.serviceItem.images[0];

          return this.userService.getUserByUid(item.creator.id);
        }),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.creator = user;
        this.galleryUrls = this.serviceItem.images.map(
          pic => `url("${pic}") no-repeat center center / cover`
        );
      });
  }

  toggleGallery(i?) {
    this.isOpenGallery = !this.isOpenGallery;
    this.config = {
      modules: [Navigation],
      loop: true,
      navigation: true,
      initialSlide: i,
    };
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.currentUser = user;
        this.currentUserID = user.uid;
        this.isAdmin = this.userService.isAdmin;
        this.userInfo = this.userService.balanceInfo;
      });
  }
}
