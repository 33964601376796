import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserService } from 'src/app/auth/user.service';
import { confirmDeletingModalData } from '../second-hand';
import { SecondHandStore } from '../second-hand-store';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';

@Component({
  selector: 'app-second-hand-item',
  templateUrl: './second-hand-item.component.html',
  styleUrls: ['./second-hand-item.component.scss'],
})
export class SecondHandItemComponent implements OnInit, OnDestroy {
  @Input() items;
  @Input() feedItemId: string;
  @Input() divider: { title: string; icon: string; color: string };
  @Input() currentUserId;
  @Input() isAdmin;
  @Input() transparent: boolean;
  @Input() status: string;

  showStatusModal: boolean = false;
  itemStatus;
  properties;

  showConfirmModal;
  confirmDeletingModalData = confirmDeletingModalData;
  disabled = false;
  loading = false;
  selectedItem;

  private destroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private router: Router,
    private store: SecondHandStore
  ) {}

  ngOnInit(): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.userService.isUserAuth();
    mixpanel.identify(uid);
  }

  trackEvent(event, parameter, parameterValue, item, currentUserId, isAdmin) {
    if (!this.secondHandSuspendedCheck(item, currentUserId, isAdmin)) {
      if (this.feedItemId) {
        this.router.navigate(['/bazaar/second-hand/', this.feedItemId]);
      } else {
        this.router.navigate(['/bazaar/second-hand/', item.id]);
      }
    }
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }
    mixpanel.track(event, this.properties);
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  closeModal(e) {
    this.showConfirmModal = false;
  }

  openConfirmModal(item, e) {
    e.stopPropagation();
    this.showConfirmModal = true;
    this.selectedItem = item;
  }

  removeItem() {
    this.disabled = true;
    this.loading = true;
    this.store.removeItem(this.selectedItem.id);
    this.store
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.showConfirmModal = false;
        if (data) {
          this.loading = false;
          this.disabled = false;
          this.showConfirmModal = false;
        } else {
          createToaster(
            'Successfully deleted!',
            'success',
            positionType.BOTTOM
          );
        }
      });
  }

  secondHandSuspendedCheck(secondHand, currentUserId, isAdmin) {
    return isAdmin
      ? false
      : !!secondHand &&
          secondHand.suspended &&
          secondHand.status === 'approved' &&
          secondHand.creator.id === currentUserId;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
