import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from '../firebase/auth.service';
import { CrudHelper } from '../firebase/helpers/crudHelper';
import { IReaction } from '../activity-feed/activity-feed';

@Injectable({
  providedIn: 'root',
})
export class ReactionsService {
  currentUser: string;

  constructor(
    private authService: AuthService,
    private crudHelper: CrudHelper
  ) {
    const { uid } = this.authService.isUserAuth();
    this.currentUser = uid;
  }

  getReactions() {
    return this.crudHelper.getCollectionItems({
      collectionName: 'reactions',
      queryFn: null,
    });
  }

  putLikeOrDislike(itemId: string, collection: string) {
    let likes = this.getReactions();
    likes.pipe(take(1)).subscribe((data: any) => {
      let currentLikes = data.find(item => item.id === itemId);
      if (currentLikes && currentLikes.likes.includes(this.currentUser)) {
        let index = currentLikes.likes.indexOf(this.currentUser);

        if (index !== -1) {
          currentLikes.likes.splice(index, 1);
        }

        return this.crudHelper.updateItem({
          data: { likes: currentLikes.likes, collection: collection },
          id: itemId,
          collectionName: 'reactions',
        });
      } else {
        if (currentLikes && !currentLikes.likes.includes(this.currentUser)) {
          currentLikes.likes = [...currentLikes.likes, this.currentUser];
          return this.crudHelper.updateItem({
            data: { likes: currentLikes.likes, collection: collection },
            id: itemId,
            collectionName: 'reactions',
          });
        } else {
          let currentReaction = [{ id: itemId, likes: [this.currentUser] }];
          return this.crudHelper.setDocument(itemId, {
            collectionName: 'reactions',
            data: { likes: currentReaction[0].likes, collection: collection },
          });
        }
      }
    });
  }
}
