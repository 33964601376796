export const environment = {
  production: true,
  api: '/api',
  hostUrl: 'https://app.mermapp.com/',
  firebaseConfig: {
   apiKey: 'AIzaSyDxlrnP5iPP5ZHjpJpF4HfHljc9go7HMAs',
   authDomain: 'mermapp-production.firebaseapp.com',
   projectId: 'mermapp-production',
   storageBucket: 'mermapp-production.appspot.com',
   messagingSenderId: '241717412079',
   appId: '1:241717412079:web:5a9b609e6be5195f1c5b1a',
   measurementId: 'G-MRPTJWTRYV',
  },
  googleMapKey: 'AIzaSyDEvI_b9iqwj_YzueWIhYf3QJPyxQis5YQ',
  routers: {},
  payment: {
    stripePublicKey:
      'pk_test_51Lv5YTL1hrltBe0fReuENsWR3VYDPvjrBjenSTiFa01lpZ2m1yZ44OsNmlVawuQI0T1T0eawu4gxuxkDmdp6Cxjm00hwYu06Wx',
    PERLS_MODE: 'payment',
    SUBSCRIPTION_MODE: 'subscription',
    EIGHT_PEARLS: {
      PRODUCT_ID: 'prod_Ntmfgo8ZRSWt7p',
      PRICE_ID: 'price_1N7z6aL1hrltBe0fkSMAeJvd',
      pearls: 8,
    },
    FORTY_TWO_PEARLS: {
      PRODUCT_ID: 'prod_NtmhDywiaxqhxF',
      PRICE_ID: 'price_1N7z7oL1hrltBe0flXiVQi2K',
      pearls: 42,
    },
    NINETY_PEARLS: {
      PRODUCT_ID: 'prod_NtminiQKa3ueHa',
      PRICE_ID: 'price_1N7z8aL1hrltBe0fUST2zAfY',
      pearls: 90,
    },
    TWO_HUNDREDS_PEARLS: {
      PRODUCT_ID: 'prod_NtmiP0nYgSDiJZ',
      PRICE_ID: 'price_1N7z98L1hrltBe0fiA7Mgxt7',
      pearls: 200,
    },
    FIVE_HUNDRED_AND_FORTY_PEARLS: {
      PRODUCT_ID: 'prod_NtmjkqUlBXjKbf',
      PRICE_ID: 'price_1N7z9iL1hrltBe0fYpaM6dSs',
      pearls: 540,
    },
    ONE_THOUSAND_TWO_HUNDRED_PEARLS: {
      PRODUCT_ID: 'prod_NtmjmP4XLGbqFb',
      PRICE_ID: 'price_1N7zAEL1hrltBe0fn7CIFkJp',
      pearls: 1200,
    },
    ONE_MONTH_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_NtmeUHJlJ7dX1d',
      PRICE_ID: 'price_1N7z58L1hrltBe0fYng6B5sG',
      period: '1 Month',
    },
    THREE_MONTHS_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_NtmeUHJlJ7dX1d',
      PRICE_ID: 'price_1N7z58L1hrltBe0fC0iuD3Bv',
      period: '3 Months',
    },
    ONE_YEAR_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_NtmeUHJlJ7dX1d',
      PRICE_ID: 'price_1N7z58L1hrltBe0flT6ju2f8',
      period: '1 Year',
    },
  },
  mixpanelToken: '05a8bcd630dd63d4c734c08ebe954e1a',
  discordServerId: '920296167177355284',
  discordServerInviteLink: 'https://discord.com/invite/PM89UWcsgt',
  pipeDreamUrl: 'https://eowmxurvi9ulyx.m.pipedream.net',
  discordServerLink: 'https://discord.com/channels/920296167177355284',
 };
 