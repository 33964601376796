import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import isEqual from 'lodash.isequal';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { CrudHelper } from './helpers/crudHelper';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private fireStore: AngularFirestore,
    private crudHelper: CrudHelper
  ) {}

  showSuccessModal$ = new BehaviorSubject<boolean>(false);

  getEvents = () => {
    return this.crudHelper.getHelper({ collectionName: 'events' });
  };

  setEvent = async event => {
    await this.crudHelper.setHelper({
      collectionName: 'events',
      data: event,
    });
    this.showSuccessModal$.next(true);
  };

  updateEvent = async (event, id) => {
    return await this.crudHelper.updateItem({
      data: event,
      id,
      collectionName: 'events',
    });
  };

  deleteEvent = async eventId => {
    return await this.crudHelper.deleteHelper({
      collectionName: 'events',
      id: eventId,
    });
  };

  getEventBySearch = async (start, end) => {
    return await this.crudHelper.searchHelper({
      collectionName: 'events',
      searchField: 'title',
      limit: 50,
      start,
      end,
    });
  };

  getEventsByFilter = filters => {
    const { creatorId, categories, price, location } = filters;

    return this.fireStore
      .collection('events', ref => {
        let newRef: any = ref;
        if (location) {
          newRef = newRef.where('location', '==', location);
        }
        if (price) {
          newRef = newRef
            .where('price.value', '>=', price.min)
            .where('price.value', '<=', price.max)
            .where('price.currency', '==', price.currency);
        }
        if (categories) {
          newRef = newRef.where('categories', 'array-contains-any', categories);
        }
        if (creatorId) {
          newRef = newRef.where('creatorId', '==', creatorId);
        }

        return newRef;
      })
      .snapshotChanges()
      .pipe(
        map(events => {
          return events.map(a =>
            Object.assign(
              {
                id: a.payload.doc.id,
              },
              a.payload.doc.data()
            )
          );
        }),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      );
  };
}
