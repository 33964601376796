import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inviteRequests',
})
export class InviteRequestsPipe implements PipeTransform {
  transform(value: unknown, userId: string): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        const { memberRequests, status } = item;
        return (
          memberRequests &&
          memberRequests.length &&
          memberRequests.find(request => request.id === userId) &&
          status === 'approved'
        );
      });
      return newValue;
    } else return value;
  }
}
