import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventApproved',
})
export class EventApprovedPipe implements PipeTransform {
  transform(value: unknown, user: string): unknown {
    if (Array.isArray(value) && value) {
      return value.filter(
        item =>
          item.status === 'approved' &&
          (item.creator.id === user ||
            item.participants.some(participant => participant.id === user))
      );
    } else return value;
  }
}
