import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CrudHelper } from '../../../firebase/helpers/crudHelper';
import { CommonService } from '../../../shared/component-store/common-service';
import { AuthService } from 'src/app/firebase/auth.service';
import { DateService } from 'src/app/services/date.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService extends CommonService {
  isOpenForm = new BehaviorSubject(false);

  constructor(
    private fb: FormBuilder,
    private firebaseHelperService: CrudHelper,
    private authService: AuthService,
    private dateService: DateService
  ) {
    super(firebaseHelperService);
  }

  addImage(images) {
    let i = images.length;
    i = this.fb.group({
      image: [null],
    });
    images.push(i);
  }

  deleteImage(images: FormArray, i: number) {
    images.removeAt(i);
  }

  getProfile(email: string) {
    return this.firebaseHelperService.searchByField('users', 'email', email);
  }

  getItems({ collectionName, filter }) {
    const queryFn = ref => {
      let query = ref;

      const { location, type, creator, status, myContent } = filter;

      if (type && type.length > 0) {
        query = query.where('type', 'in', type);
      }

      if (location && location.length) {
        query = query.where('location.country.region', 'in', location);
      }

      if (creator || myContent) {
        const user = creator || this.authService.isUserAuth().uid;
        query = query.where('creator.id', '==', user);
        query = query.where('status', 'in', [
          'approved',
          'pending',
          'rejected',
          'pending-repeat',
        ]);
      }

      if (status) {
        query = query.where('status', '==', status);
      }

      if (!status && !creator && !myContent) {
        query = query.where('status', '==', 'approved');
      }
      query = query.orderBy('createdAt', 'desc');

      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getMyItems(collectionName, creator) {
    const queryFn = ref => {
      let query = ref;

      query = query.where('creator.id', '==', creator);
      return query;
    };

    return this.firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  checkFilter(filter) {
    const { type, location, myContent } = filter;
    const result =
      filter === false ||
      ((type === null || !type?.length) &&
        (location === null || !location?.length) &&
        !myContent);
    return !result;
  }

  jobsSuspendedCheck(jobs, currentUserId) {
    if (Array.isArray(jobs)) {
      const actualItems = this.dateService.getScheduledItems(jobs, false);
      return actualItems.find(
        item =>
          item.suspended &&
          item.creator.id === currentUserId &&
          item.status === 'approved'
      );
    } else {
      const actualItem = this.dateService.getScheduledItems([jobs], false);
      return (
        !!jobs.suspended &&
        actualItem.length &&
        currentUserId === jobs.creator.id &&
        jobs.status === 'approved'
      );
    }
  }
}
