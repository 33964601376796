import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'planetApproved',
})
export class PlanetApprovedPipe implements PipeTransform {
  transform(value: unknown, user: string): unknown {
    if (Array.isArray(value) && value) {
      return value.filter(
        item =>
          item.status === 'approved' &&
          (item.creator.id === user || item.participants.includes(user))
      );
    } else return value;
  }
}
