import { NgModule } from '@angular/core';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { EventsComponent } from './events.component';
import { EventsDetailsComponent } from './events-details/events-details.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { AddParticipantsComponent } from './add-participants/add-participants.component';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EventsComponent,
          },
          {
            path: ':id',
            pathMatch: 'full',
            component: EventsDetailsComponent,
          },
          {
            path: ':id/participants',
            pathMatch: 'full',
            component: EventParticipantsComponent,
          },
          {
            path: ':id/participants/add-participants',
            pathMatch: 'full',
            component: AddParticipantsComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class EventsRoutingModule {}
