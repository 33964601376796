import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pending',
})
export class PendingPipe implements PipeTransform {
  transform(value: unknown, user: string) {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        const { status, creator } = item;
        return (
          ['pending', 'pending-repeat'].includes(status) && creator.id === user
        );
      });
      return newValue;
    } else return value;
  }
}
