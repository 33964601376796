import { Component, OnInit } from '@angular/core';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-mailing',
  templateUrl: './mailing.component.html',
  styleUrls: ['./mailing.component.scss'],
})
export class MailingComponent implements OnInit {
  emails$ = new BehaviorSubject<string[]>([]);
  constructor() {}

  async ngOnInit() {
    await this.getEmails();
  }

  async getEmails() {
    const functions = getFunctions();
    const getUsersEmails = httpsCallable(functions, 'getUsersEmails');
    const data = (await getUsersEmails()) as any;
    this.emails$.next(data.data.usersEmails);
  }
}
