import { Injectable } from '@angular/core';
import { filter, Observable, take, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonComponentStore } from '../shared/component-store/common-component-store';
import { ActivityFeedServiceService } from './activity-feed-service.service';
import { FeedItem } from './activity-feed';

@Injectable({ providedIn: 'root' })
export class ActivityFeedStore extends CommonComponentStore<FeedItem> {
  constructor(activityFeedService: ActivityFeedServiceService) {
    super(activityFeedService, {
      createdId: null,
      item: null,
      items: null,
      loading: false,
      collectionName: 'activityFeed',
      selectedItems: null,
    });
  }

  updateProfile(item) {
    return this.effect((item: Observable<any>) =>
      item.pipe(
        filter(item => !!item),
        tap(() => this.patchState({ loading: true })),
        switchMap(profile =>
          this.selectItem().pipe(
            take(1),
            switchMap(item => this.updateFeedItem(profile, profile))
          )
        )
      )
    )(item);
  }

  selectShowFormModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showFormModal);
  }

  updateShowFormModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showFormModal: value });
  }

  selectShowPendingModal() {
    // TODO update
    // @ts-ignore
    return this.select(state => state.showPendingModal);
  }

  updateShowPendingModal(value) {
    // TODO update
    // @ts-ignore
    this.patchState({ showPendingModal: value });
  }
}
