export const includesIgnoreCase = (a: string, b: string): boolean =>
  !!a && a.toLowerCase().includes(b.toLowerCase());

export const getMeta = (url: string) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = url;
  });
};
