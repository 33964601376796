import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminService } from '../admin.service';
import { CrudHelper } from '../../firebase/helpers/crudHelper';

@Component({
  selector: 'app-user-generated-content',
  templateUrl: './user-generated-content.component.html',
  styleUrls: ['./user-generated-content.component.scss'],
})
export class UserGeneratedContentComponent implements OnInit {
  flagData: any[];
  private destroy$ = new Subject<void>();

  constructor(
    private adminService: AdminService,
    private crudHelper: CrudHelper
  ) {}

  ngOnInit() {
    this.adminService
      .getFlaggedContent()
      .pipe(
        takeUntil(this.destroy$),
        map(items => items)
      )
      .subscribe((data: any[]) => {
        this.flagData = data.reverse();
      });
  }

  deleteContent(item) {
    this.crudHelper.deleteHelper({
      collectionName: 'flaggedContent',
      id: item.flagContentId,
    });
    let index = this.flagData.findIndex(flagItem => flagItem?.id === item?.id);
    this.flagData.splice(index, 1);
  }
}
