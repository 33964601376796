// @ts-ignore

import { Item } from '../shared/models/item.models';
import { IUser } from '../auth/auth.models';
import { FormField, FormFieldTypes } from '../shared/components/form/form';

export interface PlanetItem extends Item {
  title: string;
  challengeDate: any;
  description: string;
  creator: { id: string; photo: string; username: string };
  date: string;
  image: string;
  value?: string;
  participants?: IUser[];
  experiences: any;
}

export const successPlanetModalData = {
  title: 'Thanks for adding content!',
  pic: 'some sea animal with magnifying glass',
  description:
    "You'll get rewarded as soon as your content has been verified by 3 moderators.",
  okBtn: {
    title: 'CONTINUE',
  },
  cancelBtn: {
    title: 'SPLASH MERFRIENDS',
  },
};

export interface IPlanetFilter {
  myContent: boolean;
}

export const confirmAddingPlanetModalData = {
  title: 'Propose a Daily Challenge ',
  iconPath: '/assets/icon/hand-holding-heart-solid_1.svg',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmEditingPlanetModalData = {
  title: 'Edit a Daily Challenge ',
  iconPath: '/assets/icon/hand-holding-heart-solid_1.svg',
  okBtn: {
    title: 'YES, DISCARD',
  },
  cancelBtn: {
    title: 'NO, GO BACK',
  },
};

export const confirmDeletingPlanetItemModalData = {
  title: 'Deleting a challenge item',
  icon: 'shirt',
  okBtn: {
    title: 'YES, DELETE',
  },
  cancelBtn: {
    title: 'NO, KEEP',
  },
};

export const planetFormData: FormField[] = [
  {
    name: 'myContent',
    type: FormFieldTypes.TOGGLE,
    label: 'see my content only',
    required: false,
    icon: 'person',
    line: 'full',
  },
];

export const getTimeUntilNextSunday = () => {
  const now = new Date();
  const daysUntilSunday = (7 - now.getDay()) % 7;
  let nextSunday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + daysUntilSunday,
    0,
    0,
    0
  ).getTime();

  let timeUntilNextSunday = nextSunday - now.getTime();
  let days = Math.floor(timeUntilNextSunday / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (timeUntilNextSunday % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor(
    (timeUntilNextSunday % (1000 * 60 * 60)) / (1000 * 60)
  );
  let dayString = '';
  if (days) {
    dayString = `${days}${days > 1 ? ' days' : ' day'}`;
  }
  const hourString = `${hours}  ${hours > 1 ? ' hours' : ' hour'}`;
  const minutesString = `${minutes} ${minutes > 1 ? ' minutes' : ' minute'}`;

  if (days === 0) {
    return `${hourString} ${minutesString}`;
  } else if (hours === 0) {
    return `${dayString} ${minutesString}`;
  } else if (minutes === 0) {
    return `${dayString} ${hourString}`;
  } else if (days === 0 && hours === 0) {
    return `${minutesString}`;
  } else if (days === 0 && minutes === 0) {
    return `${hourString}`;
  } else if (hours === 0 && minutes === 0) {
    return `${dayString}`;
  } else {
    return `${dayString} ${hourString} ${minutesString}`;
  }
};
