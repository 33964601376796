import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { AdsenseModule } from 'ng2-adsense';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { JobsServicesComponent } from './jobs-services.component';
import { JobsServicesRoutingModule } from './jobs-services-routing.module';
import { ServicesFilterComponent } from './services/services-filter/services-filter.component';
import { AddJobComponent } from './jobs/add-job/add-job.component';
import { EditJobComponent } from './jobs/edit-job/edit-job.component';
import { ServicesSearchComponent } from './services/services-search/services-search.component';
import { ServicesComponent } from './services/services.component';
import { ServicesDetailsComponent } from './services/services-details/services-details.component';
import { ServicesItemComponent } from './services/services-item/services-item.component';
import { AddServiceComponent } from './services/add-service/add-service.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditServiceComponent } from './services/edit-service/edit-service.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { JobsDetailsComponent } from './jobs/jobs-details/jobs-details.component';
import { JobsFilterComponent } from './jobs/jobs-filter/jobs-filter.component';
import { JobsItemComponent } from './jobs/jobs-item/jobs-item.component';
import { JobsSearchComponent } from './jobs/jobs-search/jobs-search.component';
import { JobsComponent } from './jobs/jobs.component';

@NgModule({
  declarations: [
    JobsServicesComponent,
    ServicesComponent,
    ServicesItemComponent,
    ServicesFilterComponent,
    ServicesSearchComponent,
    ServicesDetailsComponent,
    AddServiceComponent,
    EditServiceComponent,
    JobsItemComponent,
    JobsFilterComponent,
    JobsSearchComponent,
    JobsDetailsComponent,
    JobsComponent,
    AddJobComponent,
    EditJobComponent,
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
    }),
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule,
    JobsServicesRoutingModule,
    SharedModule,
    IonicSelectableModule,
    GoogleMapsModule,
    GooglePlaceModule,
    PipesModule,
  ],
  exports: [
    ServicesItemComponent,
    AddServiceComponent,
    JobsItemComponent,
    AddJobComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class JobsServicesModule {}
