import { Address } from '../merfolk/map/map';
import { ICountry, Item } from '../shared/models/item.models';

export interface Shop extends Item {
  name: string;
  imageUrl: string;
  shopUrl: string;
  shopCountry: string;
}

export const shopConfirmModalData = {
  icon: 'storeFront',
  title: 'Add a Shop',
  description:
    '<p>You know of a great shop that should be promoted here? Or you own one yourself? <br />Awesome, write to us via <a  href="mailto:contact@mermapp.com" target="_blank"> contact@mermapp.com </a> and we\'ll see how we can work together.</p>',
  okBtn: {
    title: 'OKAY',
  },
};

export enum ShippingMethods {
  LOCAL = 'countrywide',
  WORLDWIDE = 'worldwide',
  PIC_UP = 'pick-up only',
}

export enum PaymentTypes {
  SALARY = 'SALARY',
  HOURLY = 'HOURLY',
}

export interface SecondHandItem extends Item {
  name: string;
  brand: string;
  images: string[];
  price: string;
  category: string;
  condition: string;
  description?: string;
  shippingOption: ShippingMethods;
  swapOption: boolean;
  country: ICountry;
  state: string;
  creator: { id: string; photo: string };
  status: string;
}

export interface JobItem extends Item {
  title: string;
  type: string;
  creator: { id: string };
  images: string[];
  account: string;
  businessName: string;
  businessWebsiteUrl: string;
  // completionDate: Date();
  companyName: string;
  status: string;
  location: Address;
  duration: string;
  availability: JobAvailability;
  salary: string;
  payment: PaymentTypes;
  suspended?: boolean;
}

export interface ServiceItem extends Item {
  completionDate: any;
  images: string[];
  title: string;
  type: string;
  creator: { id: string };
  account: string;
  businessName: string;
  businessWebsiteUrl: string;
  status: string;
  location: Address;
  duration: string;
  availability: JobAvailability;
  salary: string;
  payment: PaymentTypes;
}

export enum JobAvailability {
  PART = 'PART',
  FULL = 'FULL',
}

export const createMockShop = (): Shop => ({
  id: 'mockid',
  name: 'Test Shop',
  // eslint-disable-next-line max-len
  imageUrl:
    'https://www.finfunmermaid.com/media/catalog/product/cache/249a495e4b76751c8698f38763c2678c/a/u/aussie-green-mermaid-tail-tlx-agn-ls-1_1_1.jpg',
  shopUrl: 'https://www.finfunmermaid.com/',
  shopCountry: 'Ukraine',
});

// export const createMockSecondHandItem = (): SecondHandItem => ({
//   id: '1',
//   name: 'Test Second-Hand Item',
//   creatorId: 'ddd',
//   image:
//     'https://cdn.shopify.com/s/files/1/1109/8836/products/Capture_d_ecran_2017-12-21_a_16.15.17_530x@2x.png?v=1606111530',
//   shipping: ShippingMethods.LOCAL,
//   price: '100',

//     country: 'Ukraine',
//     city: 'Kyiv',
//   }
// });

export const createMockJob = (): JobItem => ({
  id: '1',
  payment: PaymentTypes.SALARY,
  status: 'test',
  type: 'test',
  creator: { id: '1' },
  title: 'Test Job',
  companyName: 'Test Company',
  location: {
    country: 'Ukraine',
    city: 'Kyiv',
  },
  account: 'test',
  businessName: 'test',
  businessWebsiteUrl: 'test',
  duration: '3 months',
  availability: JobAvailability.FULL,
  salary: '100',
  images: [],
});
