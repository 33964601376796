import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../../firebase/auth.service';

@Component({
  selector: 'app-follow-tabs',
  templateUrl: './follow-tabs.component.html',
  styleUrls: ['./follow-tabs.component.scss'],
})
export class FollowTabsComponent implements OnInit, OnDestroy {
  tab;
  id: string = this.activeRouter.snapshot.params.id;
  toolbarData = {
    arrowRouter: '/profile/' + this.activeRouter.snapshot.params.id,
    btnSlot: 'end',
    title: this.activeRouter.snapshot.queryParams.userName,
  };
  user;
  followersInfo: any[] = [];
  followingInfo: any[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    public activeRouter: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getUser();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getUser() {
    this.authService
      .getUserByUid(this.activeRouter.snapshot.params.id)
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.user = user;
      });
  }

  getSelected(_e) {
    this.tab = _e;
  }
}
