import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { FormService } from '../../../../shared/components/form/form.service';
import { ISecondHandFilter, secondHandMenuData } from '../../second-hand';
import { SecondHandStore } from '../../second-hand-store';
import { UserService } from 'src/app/auth/user.service';
import { IUser } from 'src/app/auth/auth.models';

@Component({
  selector: 'app-second-hand-filter',
  templateUrl: './second-hand-filter.component.html',
  styleUrls: ['./second-hand-filter.component.scss'],
})
export class SecondHandFilterComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/bazaar',
    title: 'Second Hand',
  };
  secondHandMenuData = secondHandMenuData;
  color = 'dark';
  myContent: boolean = false;
  country: string | null = null;
  currentUser: IUser;
  creator;
  menuInitialData;
  allItems = [];

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private shStore: SecondHandStore,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.formService.formData$.next(secondHandMenuData);
    this.listenFormChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFormGroupChange(_e) {
    _e.patchValue(this.menuInitialData);
  }

  private listenFormChanges() {
    this.formService.secondHandMenuData$
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data)
      )
      .subscribe((filterData: ISecondHandFilter) => {
        this.menuInitialData = filterData;
        if (filterData.priceRange) {
          this.shStore.updatePriceRange(filterData.priceRange);
        }
        if (!filterData) this.shStore.updatePriceRange('');
        this.shStore.getItems(filterData);
      });
  }
}
