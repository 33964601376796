import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PlanetDetailsComponent } from './planet-details/planet-details.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { PlanetComponent } from './planet.component';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PlanetDetailsComponent,
          },
          {
            path: 'challenges',
            pathMatch: 'full',
            component: PlanetComponent,
          },
          {
            path: 'challenges/:id',
            pathMatch: 'full',
            component: PlanetDetailsComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class PlanetRoutingModule {}
