import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import mixpanel from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CrudHelper } from './helpers/crudHelper';
import { MapService } from '../services/map.service';
import { User } from '../shared/models/user.model';
import { ProfileStore } from '../profile/store/profile-store';
import { countries } from '../auth/sign-up/countries';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  properties;
  isUserPremium;
  private apiUrl = 'https://api.mixpanel.com';
  constructor(
    private fireStore: AngularFirestore,
    private profileStore: ProfileStore,
    private crudHelper: CrudHelper,
    private mapService: MapService,
    private router: Router,
    private http: HttpClient
  ) {}

  async getSubscriptionStatus() {
    if (this.isUserPremium) {
      return true;
    }
    const decodedToken = await getAuth().currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole === 'premium';
  }

  trackEvent(event, userCredential, user, country) {
    this.properties = {
      id: userCredential.user.uid,
      country,
      region: country.region,
      city: user.address.city,
      email: user.email,
      'marketing-accept': user.isMailingEnabled,
      'premium-active': false,
    };

    mixpanel.track(event, this.properties);
  }

  getCurrentUser() {
    return getAuth().currentUser;
  }

  isUserAuth() {
    return getAuth().currentUser;
  }

  inviteMerfriend = async (recipientEmail, message, sender) => {
    const functions = getFunctions();
    const sendMail = httpsCallable(functions, 'sendMail');
    const data = await sendMail({ recipientEmail, message, sender });
    if (data) {
      return data;
    } else {
      throw 'Firebase error';
    }
  };

  signUp = async user => {
    const { address, username, email, password, isMailingEnabled } = user;
    const country = countries.find(country => country.name === address.country);
    const location = this.mapService.createCoordinates(address.googleMapLink);

    const auth = getAuth();
    const functions = getFunctions();

    const isUsernameTaken = httpsCallable(functions, 'isUsernameTaken');

    const { data } = await isUsernameTaken({ username });
    if (data) {
      throw 'auth/username-already-exist';
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const { uid } = userCredential.user;
    this.fireStore.doc(`users/${uid}`).set({
      uid,
      email,
      username,
      country,
      city: null,
      postalCode: null,
      location,
      profilePicture: null,
      gender: 'Other',
      mermaidExpLvl: null,
      mersonaName: null,
      galleryVisibility: true,
      isMailingEnabled,
      platform: Capacitor.getPlatform(),
    });
    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.identify(uid);
    this.trackEvent('New User', userCredential, user, country);

    mixpanel.people.set({
      $region: country.region,
      $city: address.city,
      $email: email,
      $country: country,
      $createdAt: new Date(),
      'marketing-accept': isMailingEnabled,
      'premium-active': false,
    });
  };

  signIn = async ({ email, password, rememberMe }) => {
    const auth = getAuth();
    const sessionType = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;

    await setPersistence(auth, sessionType);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  };

  signOut = async () => {
    await signOut(getAuth());
    this.router.navigate(['/'], { replaceUrl: true });
    this.profileStore.logOut();

    console.log('successfully sign out');
  };

  deleteProfile = async () => {
    const functions = getFunctions();
    const isDeleted = httpsCallable(functions, 'deleteUser');
    try {
      const data = await isDeleted();
      return data;
    } catch (error) {
      throw 'Firebase error';
    }
  };

  async sendResetEmail({ email }) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent');
    } catch (error) {
      console.log(error.message);
    }
  }

  getUsers = () => {
    return this.crudHelper.getHelper({ collectionName: 'users' });
  };

  getUserById = (id: string): Observable<User> => {
    return this.fireStore
      .collection('users', ref => ref.where('id', '==', id))
      .snapshotChanges()
      .pipe(
        map(user => {
          const userData = user[0].payload.doc.data() as User;
          return {
            id: user[0].payload.doc.id,
            ...userData,
          };
        })
      );
  };

  getUserByUid = uid => {
    return this.fireStore
      .collection('users', ref => ref.where('uid', '==', uid))
      .snapshotChanges()
      .pipe(
        map(user => {
          return Object.assign(
            {
              id: user[0].payload.doc.id,
            },
            user[0].payload.doc.data()
          );
        }),
        catchError(async e => {
          console.log(`User with uid ${uid} not found or not exist`);
          return { id: '' };
        })
      );
  };

  getUserBySearch = async (start, end) => {
    return await this.crudHelper.searchHelper({
      collectionName: 'users',
      searchField: 'username',
      limit: 50,
      start,
      end,
    });
  };

  setPaidPremium = () => {
    this.isUserPremium = true;
  };
}
