import { Component, OnInit, OnDestroy } from '@angular/core';
import { Platform, ViewWillEnter } from '@ionic/angular';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { showLoading } from 'src/app/shared/components/modals/loading';
import { SecondHandStore } from './second-hand-store';
import { SecondHandService } from './second-hand.service';
import { FormService } from 'src/app/shared/components/form/form.service';
import {
  ISecondHandFilter,
  secondHandDiscordModalData,
  secondHandPremiumModalData,
} from './second-hand';
import { AdmobService } from 'src/app/shared/adv/admob.service';
import { UserService } from 'src/app/auth/user.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { includesIgnoreCase } from 'src/utils';

@Component({
  selector: 'app-second-hand',
  templateUrl: './second-hand.component.html',
  styleUrls: ['./second-hand.component.scss'],
})
export class SecondHandComponent implements OnInit, OnDestroy, ViewWillEnter {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

  status;
  loader = showLoading();
  myContent: boolean = false;
  country: string | null = null;
  userId: string;
  creator;
  isOpenFormModal = false;
  items;
  allItems = [];
  color = 'dark';

  showStatusModal: boolean = false;
  itemStatus;

  isPremium: boolean;
  isAdmin: boolean;

  isOpenDiscordModal = false;
  premiumModalData = secondHandPremiumModalData;
  serviceDiscordModalData = secondHandDiscordModalData;
  isUserPremiumModalOpen = false;
  currentUser;
  infoSnapshot;
  userInfo;

  isMobile = this.platform.platforms().includes('capacitor');
  isIosApp = this.platform.platforms().includes('ios') && this.isMobile;
  isShowPage = false;

  private destroy$ = new Subject<void>();

  constructor(
    private secondHandStore: SecondHandStore,
    private secondHandService: SecondHandService,
    private fs: FormService,
    private admobService: AdmobService,
    private platform: Platform,
    private userService: UserService,
    private crudHelper: CrudHelper
  ) {}

  async ngOnInit() {
    this.getUser();
    this.listenFilter();
    this.listenStatusChanges();
    this.listenItemsChanges();
    this.secondHandStore.selectShowFormModal().subscribe(isOpen => {
      this.isOpenFormModal = isOpen;
    });
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.infoSnapshot) {
      this.infoSnapshot.unsubscribe();
    }
    this.fs.secondHandMenuData$.next(false);
    this.fs.formInitialValues.next({ myContent: null });
    await this.admobService.ionViewWillLeave();
  }

  ionViewWillEnter() {
    this.isShowPage = true;
    this.admobService.resumeBanner();
    this.admobService.ionViewWillEnter();
  }

  async ionViewWillLeave() {
    this.isShowPage = false;
    await this.admobService.hideBanner();
  }

  openModal() {
    if (this.currentUser.discordLink) {
      this.secondHandStore.updateShowFormModal(true);
    } else {
      this.isOpenDiscordModal = true;
    }
  }

  closeModal(e) {
    e ? this.secondHandStore.updateShowFormModal(false) : null;
  }

  onSearchChange(searchString) {
    this.searchString.next(searchString);
  }

  openStatusModal(status?) {
    this.showStatusModal = !this.showStatusModal;
    if (status) this.itemStatus = status;
  }

  private listenFilter() {
    this.fs.secondHandMenuData$.subscribe(data => {
      const initialData = this.secondHandService.checkFilter(data);
      if (initialData) {
        this.color = 'success';
      } else {
        this.color = 'dark';
      }

      this.myContent = (data as ISecondHandFilter).myContent;
      this.secondHandStore.getItems(data);
      if (!data) this.secondHandStore.updatePriceRange('');
    });
  }

  private listenStatusChanges() {
    this.secondHandStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        loading ? (await this.loader).present() : (await this.loader).dismiss();
      });
  }

  private listenItemsChanges() {
    combineLatest([
      this.secondHandStore.selectItems(),
      this.secondHandStore.selectPriceRange(),
      this.searchString,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([items, priceRange, searchString]) =>
            items &&
            items
              .filter(item => includesIgnoreCase(item.name, searchString))
              .filter(item =>
                priceRange.length
                  ? this.secondHandService.priceRangeFilter(item, priceRange)
                  : item
              )
        ),

        switchMap((items: any[]) => {
          if (!items?.length) return (this.items = []);
          const users = this.crudHelper.getCollectionItems({
            collectionName: 'users',
            queryFn: null,
          });
          return users.pipe(
            filter(users => !!users),
            map(users =>
              items.map(item => {
                const user: any = users.find(
                  (user: any) => user.id === item.creator.id
                );
                if (user) return Object.assign({ ...item, creator: user });
                return item;
              })
            )
          );
        })
      )
      .subscribe(items => {
        this.items = items.filter(
          item => !this.currentUser?.blockList?.includes(item.creator.id)
        );
      });
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(async user => {
        this.currentUser = user;
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
        this.userId = user.uid;
        this.userInfo = this.userService.balanceInfo;
        if (!this.isAdmin && this.isMobile && !this.isPremium) {
          this.admobService.showBottomBanner();
        } else {
          await this.admobService.ionViewWillLeave();
        }
      });
  }
}
