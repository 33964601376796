import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  filter,
  takeUntil,
} from 'rxjs';
import { countries } from 'src/app/auth/sign-up/countries';
import {
  FormService,
  linkRegex,
} from 'src/app/shared/components/form/form.service';
import { showLoading } from 'src/app/shared/components/modals/loading';
import {
  imageField,
  confirmDeletingModalData,
  confirmEditingModalData,
} from '../jobs';
import { JobsService } from '../jobs.service';
import { JobsStore } from '../jobs-store';
import {
  createToaster,
  positionType,
} from 'src/app/shared/components/modals/toaster';
import { UserService } from 'src/app/auth/user.service';
import { ImagesService } from 'src/app/services/images.service';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss'],
})
export class EditJobComponent implements OnInit, OnDestroy {
  @Output() isClose = new EventEmitter<boolean>(false);
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('map') mapRef;
  @ViewChild('imageLoader') imageLoader;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;

  map;
  isOpen = false;
  countries = countries;
  jobForm: FormGroup;
  showSuccessModal = false;
  showForDelete = false;
  newJobItem;
  jobItem;
  disabled = true;
  addingBtnDisabled = true;
  imageField = imageField;
  typeOptions = ['part-time', 'full-time', 'per hour', 'gig based'];
  id: string = this.activatedRouter.snapshot.params.id;
  successModalData;
  statusDeleting: boolean;
  statusAdding: boolean;
  imagesControls = 1;
  formIsReset = new BehaviorSubject(false);
  businessAccount = false;

  businessWebsiteURLValue = null;
  businessNameValue = null;

  status;
  currentImage: string;
  loadingImage: boolean = false;
  imagesArr: string[];

  isOnline = false;
  loader = showLoading();
  isLocationReset = new BehaviorSubject<boolean>(false);
  user;
  initialData;
  item = { icon: 'pricetag', title: 'Edit your Service Offer' };
  confirmModalData = confirmEditingModalData;
  confirmForDelete = confirmDeletingModalData;
  showConfirmModal = false;

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private router: Router,
    private userService: UserService,
    private imagesService: ImagesService,
    private activatedRouter: ActivatedRoute,
    private jobsService: JobsService,
    private jobsStore: JobsStore,
    private fb: FormBuilder,
    private mapService: MapService
  ) {}

  ngOnInit() {
    this.listenStatusChanges();
    this.jobForm = this.fb.group({
      images: this.fb.array([
        {
          0: this.fb.group({
            image: [null],
          }),
        },
      ]),
      title: ['', [Validators.required, Validators.minLength(7)]],
      type: ['', [Validators.required]],
      salary: this.fb.group({
        value: [null, [Validators.required]],
        currency: ['$'],
      }),
      description: ['', [Validators.required, Validators.minLength(20)]],
      location: this.fb.group({
        online: [false, [Validators.required]],
        country: [null],
        state: [null],
        googleMapLink: [null, [Validators.required]],
      }),

      account: ['my member account'],
    });

    this.jobForm
      .get('location')
      .get('country')

      .valueChanges.pipe(
        filter(value => value.name !== this.initialData?.location.country.name)
      )
      .subscribe(() => {
        if (this.jobForm.get('location.googleMapLink').value === null) {
          return;
        }
        this.jobForm.patchValue({
          location: {
            state: null,
            googleMapLink: null,
          },
        });
        this.formService.location$.next({ googleMapLink: null });
      });

    this.getUser();

    this.jobsStore.getItemByByValue({
      documentId: this.activatedRouter.snapshot.params.id,
    });
    this.imagesService.imagesLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.loadingImage = loading;
        this.disabled = loading;
      });

    this.imagesService.compressedImages
      .pipe(takeUntil(this.destroy$))
      .subscribe(images => {
        this.imagesArr = images;
      });

    this.jobForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.disabled =
          this.jobForm.status === 'INVALID' ||
          this.status === 'loading' ||
          this.loadingImage ||
          !this.imagesArr.length;
      });
    this.initialDataListener();
    this.jobForm
      .get('account')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === 'a business account') {
          this.businessAccount = true;
          this.jobForm.addControl(
            'businessName',
            new FormControl(this.businessNameValue, [Validators.required])
          );
          this.jobForm.addControl(
            'businessWebsiteUrl',
            new FormControl(this.businessWebsiteURLValue, [
              Validators.required,
              Validators.pattern(linkRegex),
            ])
          );
        } else {
          this.businessAccount = false;
          this.jobForm.removeControl('businessName');
          this.jobForm.removeControl('businessWebsiteUrl');
        }
      });

    this.jobForm
      .get('location.online')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value) {
          this.isOnline = true;
          (this.jobForm.get('location') as FormGroup).removeControl('country');
          (this.jobForm.get('location') as FormGroup).removeControl('state');
          (this.jobForm.get('location') as FormGroup).removeControl(
            'googleMapLink'
          );
        } else {
          this.isOnline = false;
          (this.jobForm.get('location') as FormGroup).addControl(
            'country',
            new FormControl(this.initialData.location.country, [
              Validators.required,
            ])
          );
          (this.jobForm.get('location') as FormGroup).addControl(
            'state',
            new FormControl(this.initialData.location.state, [
              Validators.required,
            ])
          );
          (this.jobForm.get('location') as FormGroup).addControl(
            'googleMapLink',
            new FormControl(this.initialData.location.googleMapLink)
          );
        }
      });
  }

  ngOnDestroy() {
    // this.imagesService.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  addMapLink(e) {
    e.valueChanges.subscribe(async () => {
      const location = e.get('googleMapLink')?.value;
      const { country } = await this.mapService.getAddress(location);

      let currentCountry = countries.find(item => item.name === country);

      this.jobForm.get('location.googleMapLink')?.patchValue(location);
      this.jobForm.get('location.country').patchValue(currentCountry);
    });
  }

  initialDataListener() {
    this.jobsStore
      .selectItem()
      .pipe(
        filter(item => !!item),
        takeUntil(this.destroy$)
      )
      .subscribe((data: any) => {
        this.businessNameValue = data.businessName;
        this.businessWebsiteURLValue = data.businessWebsiteUrl;
        this.jobForm.patchValue(data);
        this.formService.location$.next({
          googleMapLink: data.location.googleMapLink,
        });

        this.initialData = data;
        if (data.account === 'a business account') {
          this.businessAccount = true;
          this.jobForm.addControl(
            'businessName',
            new FormControl(data.businessName, [Validators.required])
          );
          this.jobForm.addControl(
            'businessWebsiteUrl',
            new FormControl(data.businessWebsiteUrl)
          );
        } else {
          this.businessAccount = false;
          this.jobForm.removeControl('businessName');
          this.jobForm.removeControl('businessWebsiteUrl');
        }
        this.jobItem = data;
        this.imagesService.imageUrls.next(data.images);
        this.imagesService.compressedImages.next(data.images);
      });
    this.formService.currentPicture.subscribe(pic => {
      this.currentImage = pic;
    });
    this.imagesService.imagesLoading
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => {
        this.loadingImage = loading;
        this.disabled = loading;
      });

    combineLatest([
      this.imagesService.compressedImages,
      this.jobForm.valueChanges,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([images, value]) => {
        this.imagesArr = images;
        this.disabled =
          this.jobForm.status === 'INVALID' ||
          this.loadingImage ||
          !images.length;
        this.newJobItem = {
          ...value,
          id: this.id,
          creator: this.jobItem.creator,
        };
      });
  }

  async updateItem() {
    this.jobsStore.setLoading();
    await this.imageLoader.upLoadImages();
    this.disabled = true;
    this.imagesService.imageUrls
      .pipe(filter(data => !!data))
      .subscribe(urls => {
        if (urls.length && urls.length === this.imagesArr.length) {
          this.newJobItem.images = [...urls].filter(image => !!image);
          (this.newJobItem.location = this.newJobItem.location.online
            ? Object.assign({
                ...this.newJobItem.location,
                country: { region: 'online' },
              })
            : this.newJobItem.location),
            delete this.newJobItem.status;
          this.formService.itemGallery.next([]);

          this.jobsStore.updateProfile(
            Object.assign({
              ...this.newJobItem,
              createdAt: this.initialData.createdAt,
            })
          );
        }
      });
    this.jobsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.disabled = true;
          this.statusAdding = true;
        } else {
          this.isClose.next(true);
          createToaster(
            'Successfully updated!',
            'success',
            positionType.BOTTOM
          );
          this.disabled = false;
          this.statusAdding = false;
        }
      });
  }

  removeItem(e) {
    if (e) {
      this.jobsStore.removeItem(this.id);
      this.jobsStore
        .selectLoading()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.statusDeleting = data;
          if (data) {
            this.disabled = true;
            this.showForDelete = false;
          } else {
            this.isClose.next(true);
            createToaster(
              'Successfully deleted!',
              'success',
              positionType.BOTTOM
            );
            setTimeout(() => {
              this.router.navigate(['bazaar/job&services/jobs']);
            }, 0);
          }
        });
    }
  }

  getFormsControls(): FormArray {
    return <FormArray>this.jobForm.controls['images'];
  }

  close(e) {
    e ? (this.showConfirmModal = true) : null;
  }

  discardChanges(e) {
    if (e) {
      this.isClose.next(true);
      this.showConfirmModal = false;
    }
  }

  closeModal(e) {
    if (e) {
      this.showConfirmModal = false;
      this.showForDelete = false;
    }
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.user = user;
      });
  }

  private listenStatusChanges() {
    this.jobsStore
      .selectLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async loading => {
        loading ? (await this.loader).present() : (await this.loader).dismiss();
      });
  }
}
