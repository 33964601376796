import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../firebase/auth.service';
@Component({
  selector: 'app-deep-link-page',
  templateUrl: './deep-link-page.component.html',
  styleUrls: ['./deep-link-page.component.scss'],
})
export class DeepLinkPageComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private activatedRouter: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const authUser = this.authService.getCurrentUser();
    mixpanel.init(environment.mixpanelToken, { debug: true });
    mixpanel.identify(authUser?.uid);
    const { route, eventType } = this.activatedRouter.snapshot.queryParams;

    if (route && route.length > 0) {
      mixpanel.track('Notification Received', {
        type: eventType,
      });
      this.router.navigate(['/' + route]);
    } else {
      mixpanel.track('Notification Opened', {
        type: eventType,
      });
      this.router.navigate(['/']);
    }
  }
}
