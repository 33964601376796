import { Component, Input } from '@angular/core';
import { IUser } from 'src/app/auth/auth.models';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {
  @Input() users: IUser[];

  constructor() {}
}
