import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { AdsenseModule } from 'ng2-adsense';
import { BazaarRoutingModule } from './bazaar-routing.module';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { ShopsComponent } from './shops/shops.component';
import { BazaarMenuComponent } from './bazaar-menu/bazaar-menu.component';
import { SecondHandComponent } from './second-hand/second-hand.component';
import { AddSecondHandItemComponent } from './second-hand/add-second-hand-item/add-second-hand-item.component';
import { EditSecondHandItemComponent } from './second-hand/edit-second-hand-item/edit-second-hand-item.component';
import { JobsServicesModule } from './jobs-services/jobs-services.module';
import { PipesModule } from '../pipes/pipes.module';
import { SecondHandDetailsComponent } from './second-hand/second-hand-details/second-hand-details.component';
import { SecondHandFilterComponent } from './second-hand/second-hand-filter/second-hand-filter/second-hand-filter.component';
import { SecondHandItemComponent } from './second-hand/second-hand-item/second-hand-item.component';
import { SecondHandSearchComponent } from './second-hand/second-hand-search/second-hand-search.component';

@NgModule({
  declarations: [
    BazaarMenuComponent,
    ShopsComponent,
    SecondHandComponent,
    AddSecondHandItemComponent,
    EditSecondHandItemComponent,
    SecondHandDetailsComponent,
    SecondHandFilterComponent,
    SecondHandItemComponent,
    SecondHandSearchComponent,
  ],
  imports: [
    AdsenseModule.forRoot({
      adClient: 'ca-pub-2609549176457465',
    }),
    IonicSelectableModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule,
    BazaarRoutingModule,
    SharedModule,
    PipesModule,
    JobsServicesModule,
  ],
  exports: [SecondHandItemComponent, AddSecondHandItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BazaarModule {}
