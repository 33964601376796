import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { catchError, filter } from 'rxjs';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { PodService } from 'src/app/merfolk/pods/pods.service';
import {
  invitationEmptyModalData,
  invitationModalData,
} from 'src/app/merfolk/pods/pods/pods';
import { IPod } from 'src/app/shared/models/item.models';

@Component({
  selector: 'app-pod-invitation-modal',
  templateUrl: './pod-invitation-modal.component.html',
  styleUrls: ['./pod-invitation-modal.component.scss'],
})
export class PodInvitationModalComponent implements OnChanges {
  @Input() podsSuggestions;
  @Input() currentUser;
  @Input() userId;
  @Input() showInvitationModal;
  @Output() isOpen = new EventEmitter<boolean>();

  selectedPods: IPod[] = [];
  suggestions;

  invitationModalData = invitationModalData;
  invitationEmptyModalData = invitationEmptyModalData;

  spinner = false;

  constructor(private podService: PodService, private podStore: PodStore) {}

  ngOnChanges() {
    this.suggestions = this.podsSuggestions ? [...this.podsSuggestions] : [];
  }

  discardChanges(e: boolean) {
    if (e) {
      this.isOpen.next(false);
    }
  }

  invite(e: boolean) {
    this.suggestions = [...this.podsSuggestions];
    this.spinner = true;
    if (e && this.selectedPods.length) {
      this.podService
        .sendInvitationToPod(
          this.selectedPods,
          this.userId,
          this.currentUser.uid
        )

        .pipe(
          filter(data => !!data),
          catchError(async e => console.log(e))
        )
        .subscribe(data => {
          this.selectedPods = [];
          this.isOpen.next(false);
          this.podStore.updateInviteModal(true);
          this.spinner = false;
        });
    }
  }

  checkInvitation(pod) {
    return pod?.invites?.find(invite => invite.invited === this.userId);
  }

  chosePod(e, pod) {
    if (e.detail.checked) {
      this.selectedPods.push(pod);
    } else if (!e.detail.checked) {
      this.selectedPods = this.selectedPods.filter(item => item.id !== pod.id);
    }
  }
}
