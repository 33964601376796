// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../node_modules/flag-icons/css/flag-icons.min.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":host {\n  --height: auto;\n  --ion-toolbar-background: rgba(255, 255, 255, .62);\n  --ion-toolbar-color: var(--ion-color-dark);\n}\n:host .merfolk-searchbar {\n  --background: rgba(255, 255, 255, .62);\n}\n:host ion-item {\n  --padding-start: 0;\n  --inner-padding-end: 0;\n}\n:host ion-searchbar,\n:host .sc-ion-searchbar-ios-h {\n  --border-radius: 18px;\n  --box-shadow: none;\n  padding: 8px;\n}\n:host ion-list {\n  padding-top: 3px;\n}\n\nion-icon {\n  color: var(--ion-color-dark);\n}\n\nion-text {\n  width: 75%;\n  align-items: center;\n  text-align: center;\n}\n\nion-modal::part(backdrop) {\n  background: rgba(0, 0, 0, 0.5);\n  opacity: 1;\n}\n\nion-card {\n  box-shadow: none;\n}", "",{"version":3,"sources":["webpack://./src/app/planet/add-experience/add-experience.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EAWA,kDAAA;EACA,0CAAA;AAVF;AAAE;EACE,sCAAA;AAEJ;AACE;EACE,kBAAA;EACA,sBAAA;AACJ;AAKE;;EAEE,qBAAA;EACA,kBAAA;EACA,YAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;;AAQA;EACE,4BAAA;AALF;;AAQA;EACE,UAAA;EACA,mBAAA;EACA,kBAAA;AALF;;AAQA;EACE,8BAAA;EACA,UAAA;AALF;;AAQA;EACE,gBAAA;AALF","sourcesContent":["@import \"flag-icons/css/flag-icons.min.css\";\n\n:host {\n  --height: auto;\n\n  .merfolk-searchbar {\n    --background: rgba(255, 255, 255, .62);\n  }\n\n  ion-item {\n    --padding-start: 0;\n    --inner-padding-end: 0;\n  }\n\n  --ion-toolbar-background: rgba(255, 255, 255, .62);\n  --ion-toolbar-color: var(--ion-color-dark);\n\n  ion-searchbar,\n  .sc-ion-searchbar-ios-h {\n    --border-radius: 18px;\n    --box-shadow: none;\n    padding: 8px\n  }\n\n  ion-list {\n    padding-top: 3px;\n  }\n}\n\nion-icon {\n  color: var(--ion-color-dark)\n}\n\nion-text {\n  width: 75%;\n  align-items: center;\n  text-align: center;\n}\n\nion-modal::part(backdrop) {\n  background: rgb(0 0 0 / 0.5);\n  opacity: 1;\n}\n\nion-card {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
