import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IonMenu } from '@ionic/angular';
import { Subject, takeUntil } from 'rxjs';
import { EventsService } from 'src/app/firebase/events.service';
import { AuthService } from 'src/app/firebase/auth.service';
import { FormService } from '../form/form.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  constructor(
    private eventsService: EventsService,
    private authService: AuthService,
    private formService: FormService
  ) {}

  @Input() contentId: string;
  @Input() template: TemplateRef<any>;
  @ViewChild('menu') menu: IonMenu;

  close = false;
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.formService.menuIsClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data && this.menu) this.menu.close();
      });
  }

  async applyFilter() {
    const formDataTest = {
      myContent: true,
    };

    const { uid } = this.authService.isUserAuth();

    const mockData = {
      creatorId: formDataTest.myContent ? uid : null,
      categories: null,
      price: {
        min: 1,
        max: 10,
        currency: 'BYN',
      },
      location: null,
      date: {
        min: 'Thu Dec 22 2023',
        max: 'Thu Dec 29 2023',
      },
    };

    this.eventsService.getEventsByFilter(mockData).subscribe(data => {
      console.log(data);
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
