import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'denied',
})
export class DeniedPipe implements PipeTransform {
  transform(value: unknown, userId: unknown[]): unknown {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(item => {
        const { memberRequests, status, invites, denied, participants } = item;
        const isPendingRequest =
          memberRequests &&
          memberRequests.length &&
          memberRequests.find(request => request.id === userId) &&
          status === 'approved';
        const isInvited =
          invites &&
          invites.length &&
          invites.find(invitation => invitation.invited === userId) &&
          status === 'approved';

        return isPendingRequest || isInvited
          ? null
          : denied &&
              denied.find(item => item === userId) &&
              status === 'approved' &&
              !participants.includes(userId);
      });
      return newValue;
    } else return value;
  }
}
