import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';
import { CrudHelper } from '../../../../firebase/helpers/crudHelper';

@Component({
  selector: 'app-user-generated-content-modal',
  templateUrl: './user-generated-content-modal.component.html',
  styleUrls: ['./user-generated-content-modal.component.scss'],
})
export class UserGeneratedContentModalComponent implements OnInit {
  @Input() isOpenFlagModal: boolean;
  @Input() flagData: any;
  @Output() isOpenFlagModalChange = new EventEmitter<any>();

  flagMessageForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private crudHelper: CrudHelper,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.flagMessageForm = this.formBuilder.group({
      flagMessage: ['', Validators.required],
    });
  }

  generateId(): string {
    return uuidv4();
  }

  async createToaster(message: string) {
    const t = await this.toastController.create({
      message,
      color: 'danger',
      duration: 3000,
      position: 'bottom',
    });
    t.present();
  }

  closeModal() {
    this.isOpenFlagModalChange.next(false);
  }

  addNewFlagContent() {
    let id = this.generateId();
    this.flagData.flagMessage = this.flagMessageForm.value.flagMessage;
    this.flagData.flagContentId = id;
    this.closeModal();
    try {
      this.crudHelper.setDocument(id, {
        collectionName: 'flaggedContent',
        data: this.flagData,
      });
    } finally {
      this.createToaster('Content was flagged');
    }
  }
}
