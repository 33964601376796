import { Component, OnDestroy, OnInit } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { takeUntil, filter, Subject } from 'rxjs';
import { AuthService } from '../../firebase/auth.service';
import { environment } from '../../../environments/environment';
import { UserService } from 'src/app/auth/user.service';

@Component({
  selector: 'app-bazaar-menu',
  templateUrl: './bazaar-menu.component.html',
  styleUrls: ['./bazaar-menu.component.scss'],
})
export class BazaarMenuComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/',
    btnSlot: 'end',
    title: 'Bazaar',
  };

  properties;
  isAdmin = false;
  isPremium: boolean;
  routerTitle: string;
  private destroy$ = new Subject<void>();
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getUser();
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);
  }

  trackEvent(event, parameter, parameterValue) {
    if (parameter === 'screen-name') {
      this.properties = {
        'screen-name': parameterValue,
      };
    }

    mixpanel.track(event, this.properties);
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user.uid)
      )
      .subscribe(user => {
        this.isPremium = this.userService.isPremium;
        this.isAdmin = this.userService.isAdmin;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
