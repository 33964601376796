import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: unknown[], field: string, order: string): unknown {
    if (!value || order === '' || !order || field === '' || !field) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (field === 'completionDate.startDate') {
      const result = value.sort((a: any, b: any) => {
        const aStartDateSeconds = a.completionDate.startDate.seconds,
          bStartDateSeconds = b.completionDate.startDate.seconds;
        if (aStartDateSeconds < bStartDateSeconds) {
          return -1;
        }
        if (aStartDateSeconds > bStartDateSeconds) {
          return 1;
        }
        if (aStartDateSeconds === bStartDateSeconds) {
          return 0;
        }
      });
      return order === 'asc' ? result : result.reverse();
    }

    if (
      ['startDate', 'createdAt', 'completionDate.startDate'].includes(field)
    ) {
      const result = value.sort((a: any, b: any) => {
        if (a[field].seconds) {
          if (a[field].seconds < b[field].seconds) return -1;
          if (a[field].seconds > b[field].seconds) return 1;
          if (a[field].seconds === b[field].seconds) return 0;
        }
        if (a[field]._seconds) {
          if (a[field]._seconds < b[field]._seconds) return -1;
          if (a[field]._seconds > b[field]._seconds) return 1;
          if (a[field]._seconds === b[field]._seconds) return 0;
        }
      });
      return order === 'asc' ? result : result.reverse();
    }

    const result = value.sort((a, b) => {
      if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
      if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
      if (a[field].toLowerCase() === b[field].toLowerCase()) return 0;
    });

    return order === 'asc' ? result : result.reverse();
  }
}
