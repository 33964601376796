import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mrec',
  templateUrl: './mrec.component.html',
  styleUrls: ['./mrec.component.scss'],
})
export class MrecComponent implements OnInit {
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}
}
