// @ts-nocheck
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  userProperties$ = new BehaviorSubject<string[]>([]);
  constructor() {}

  async initWonderPush(uid: string) {
    window.WonderPush = window.WonderPush || [];

    WonderPush.push([
      'init',
      {
        webKey: environment.WONDERPUSH.WEBTOKEN,
        userId: uid,
        // optInOptions: {
        //   externalBoxSuccessMessage: null,
        // },
      },
    ]);
    await WonderPush.push(['setUserConsent', true]);
    this.subscribe(uid);
  }

  subscribe(uid: string) {
    WonderPush.push(['setUserId', uid]);
    WonderPush.subscribeToNotifications();
    this.getUserProperties(WonderPush, 'web');
  }

  unsubscribe() {
    WonderPush.unsubscribeFromNotifications();
    WonderPush.push(['unsetUserId']);
  }

  removeUserData() {
    WonderPush.unsubscribeFromNotifications();
    WonderPush.push(['clearAllData']);
    WonderPush.push(['unsetUserId']);
  }

  updateUsersProperties(props) {
    let userProperties = this.userProperties$.value;
    if (userProperties.includes(props.title)) {
      userProperties = userProperties.filter(item => item !== props.title);
    } else {
      userProperties.push(props.title);
    }
    this.userProperties$.next(userProperties);
    WonderPush.push([
      'putProperties',
      {
        string_merfolkTopics: userProperties,
      },
    ]);
  }

  getUserProperties(wonderPush, platform) {
    //!  settimeout is chosen because the push method don't see 'this'
    setTimeout(async () => {
      const props = await wonderPush.getPropertyValues('string_merfolkTopics');
      this.userProperties$.next(props);

      const defaultProperties = [
        'test',
        'merfolk',
        'events',
        'likes',
        'comments',
        'daily_challenges',
      ];
      if (!props.length && platform !== 'capacitor') {
        wonderPush.push([
          'putProperties',
          {
            string_merfolkTopics: defaultProperties,
          },
        ]);
        this.getUserProperties(wonderPush, 'web');
      } else if (!props.length && platform === 'capacitor') {
        wonderPush.putProperties({
          string_merfolkTopics: defaultProperties,
        });
        this.getUserProperties(wonderPush, 'capacitor');
      }
    }, 0);
  }
}
