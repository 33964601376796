import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flagPipe',
})
export class FlagPipe implements PipeTransform {
  transform(value: any[] | any, ...args: unknown[]) {
    if (Array.isArray(value) && value) {
      const newValue = value.map(i => {
        return Object.assign(
          {
            ...i,
          },
          {
            flag: `fi fi-${i.country ? i.country.code.toLowerCase() : ''}`,
          }
        );
      });
      return newValue;
    } else if (value) {
      const newValue = Object.assign(
        {
          ...value,
        },
        {
          flag: `fi fi-${value.country.code.toLowerCase()}`,
        }
      );
      return newValue;
    }
  }
}
