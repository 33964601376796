import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EventsStore } from '../events-store';
import { AuthService } from '../../firebase/auth.service';
import { IToolbarData } from '../../shared/models/components-data.models';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/auth/user.service';
import { CrudHelper } from 'src/app/firebase/helpers/crudHelper';
import { PodStore } from 'src/app/merfolk/pods/pod-store';
import { successInviteModalData } from 'src/app/merfolk/pods/pods/pods';
import { IPod } from 'src/app/shared/models/item.models';
import { includesIgnoreCase } from 'src/utils';

@Component({
  selector: 'app-event-participants',
  templateUrl: './event-participants.component.html',
  styleUrls: ['./event-participants.component.scss'],
})
export class EventParticipantsComponent implements OnInit, OnDestroy {
  id = this.activeRouter.snapshot.params.id;
  showConfirmModal = false;
  TOOLBAR_DATA: IToolbarData = {
    arrowRouter: '/events/' + this.id,
    btnSlot: 'end',
    title: 'Event participants',
  };
  eventItem;
  currentUser;
  currentUserUID;
  creator;
  galleryUrls: string[];
  users$ = new BehaviorSubject<User[]>([]);
  allUsers$ = new BehaviorSubject<User[]>([]);
  color = 'dark';
  searchString = new BehaviorSubject<string>('');
  userPods = new BehaviorSubject<IPod[]>([]);

  successModalData = successInviteModalData;
  showSuccessModal = false;

  private destroy$ = new Subject<void>();

  constructor(
    private eventStore: EventsStore,
    private router: ActivatedRoute,
    private authService: AuthService,
    private activeRouter: ActivatedRoute,
    private userService: UserService,
    private crudHelper: CrudHelper,
    private podStore: PodStore
  ) {}

  ngOnInit() {
    this.getUser();
    this.podStore.selectInviteModal().subscribe(isOpen => {
      this.showSuccessModal = isOpen;
    });
    this.eventStore.getItemByByValue({
      documentId: this.router.snapshot.params.id,
    });

    this.listenSecondHandDetails();
    this.listenParticipantsFilter();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleOk(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  handleCancel(e) {
    e ? this.podStore.updateInviteModal(false) : null;
  }

  searchUser($event) {
    this.searchString.next($event.target.value);
  }

  private getUser() {
    this.userService.currentUser$
      .pipe(
        filter(user => !!user.uid),
        takeUntil(this.destroy$),
        switchMap(async user => {
          this.currentUser = user;
          return this.crudHelper.getCollectionItems({
            collectionName: 'pods',
            queryFn: query =>
              query
                .where('status', '==', 'approved')
                .where('participants', 'array-contains', user.id),
          });
        })
      )
      .subscribe(pods => {
        this.userPods.next(pods as any);
      });
  }

  private listenSecondHandDetails() {
    this.eventStore
      .selectItem()
      .pipe(
        takeUntil(this.destroy$),
        filter(item => !!item),
        switchMap(item => {
          this.eventItem = item;
          const allUsers$ = this.eventItem.participants.map(user =>
            this.authService.getUserByUid(user.id)
          );
          combineLatest(allUsers$)
            .pipe(filter(users => !!users))
            .subscribe((result: User[]) => {
              this.allUsers$.next(result);
            });
          return this.authService.getUserByUid(item.creator.id);
        })
      )
      .subscribe(user => {
        this.creator = user;
        this.galleryUrls = this.eventItem.images.map(
          pic => `url("${pic}") no-repeat center center / cover`
        );
      });
  }

  listenParticipantsFilter() {
    combineLatest([this.allUsers$, this.searchString])
      .pipe(
        map(
          ([users, searchString]) =>
            users &&
            users.filter(item =>
              includesIgnoreCase(item.username, searchString)
            )
        )
      )
      .subscribe(users => this.users$.next(users));
  }
}
