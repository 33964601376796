import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Observable } from 'rxjs';
import { PodService } from '../../merfolk/pods/pods.service';
import { CommunityStore } from '../../merfolk/community/community-store';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-follow-list-item',
  templateUrl: './follow-list-item.component.html',
  styleUrls: ['./follow-list-item.component.scss'],
})
export class FollowListItemComponent {
  @Input() user;
  @Input() currentUser;
  @Input() currentTab: string;
  @Input() searchString: string;
  @Input() userPods;
  @Input() currentPage: any;
  @Input() userCount;
  @Output() currentPageChange = new EventEmitter<any>();
  @Output() functionEvent1 = new EventEmitter<Function>();
  @Output() functionEvent2 = new EventEmitter<Function>();

  podsSuggestions$: Observable<any>;
  showInvitationModal = false;

  loading;
  constructor(
    private router: Router,
    private podService: PodService,
    private communityStore: CommunityStore,
    private profileService: ProfileService
  ) {}

  goToProfile(route) {
    this.router.navigate(route);
  }

  async follow(event) {
    this.loading = true;
    const functions = getFunctions();
    const follow = httpsCallable(functions, 'follow');
    try {
      await follow({ id: this.user?.uid });
    } catch (error) {
      console.log(error);
    } finally {
      if (this.searchString.length > 0) {
        this.functionEvent2.emit();
      } else {
        this.functionEvent1.emit();
      }
      this.loading = false;
    }
  }

  async unfollow() {
    this.loading = true;
    const functions = getFunctions();
    const unfollow = httpsCallable(functions, 'unfollow');
    try {
      await unfollow({ id: this.user?.uid });
    } catch (err) {
      console.log(err);
    } finally {
      if (this.searchString.length > 0) {
        this.functionEvent2.emit();
      } else {
        if ((this.userCount - 1) % 10 === 0) {
          const newCurrentPage =
            this.profileService.currentFollowingPage.value - 1 < 0
              ? 0
              : this.profileService.currentFollowingPage.value - 1;
          this.profileService.currentFollowingPage.next(newCurrentPage);
          this.functionEvent1.emit();
        } else {
          this.functionEvent1.emit();
        }
      }
      this.loading = false;
    }
  }

  removeUserFromBlockList() {
    this.loading = true;
    const functions = getFunctions();
    const removeFromBlockList = httpsCallable(functions, 'removeFromBlockList');
    try {
      removeFromBlockList({ id: this.user?.uid });
    } catch (err) {
      console.log(err);
    } finally {
      if (this.searchString.length > 0) {
        this.functionEvent2.emit();
      } else {
        if ((this.userCount - 1) % 10 === 0) {
          const newCurrentPage =
            this.profileService.currentFollowingPage.value - 1 < 0
              ? 0
              : this.profileService.currentFollowingPage.value - 1;
          this.profileService.currentFollowingPage.next(newCurrentPage);
          this.functionEvent1.emit();
        } else {
          this.functionEvent1.emit();
        }
      }
      this.loading = false;
    }
  }

  inviteToPod(e) {
    e.stopPropagation();
    this.podsSuggestions$ = this.podService.inviteToPod(this.user.uid);
    this.showInvitationModal = true;
  }
}
