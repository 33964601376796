import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FormService } from '../../../../shared/components/form/form.service';
import { IServicesFilter, servicesMenuData } from '../services';
import { ServicesStore } from '../services.store';
@Component({
  selector: 'app-services-filter',
  templateUrl: './services-filter.component.html',
  styleUrls: ['./services-filter.component.scss'],
})
export class ServicesFilterComponent implements OnInit, OnDestroy {
  toolbarData = {
    arrowRouter: '/bazaar',
    title: 'Services',
  };
  selectedStartDate: string;
  selectedEndDate: string;
  isOpenStartDateModal = false;
  isOpenEndDateModal = false;
  color = 'dark';
  myContent: boolean = false;
  location: string | null = null;
  form = new BehaviorSubject(true);

  serviceForm;
  servicesMenuData = servicesMenuData;
  menuInitialData;
  allItems = [];

  private destroy$ = new Subject<void>();

  constructor(
    private formService: FormService,
    private jobsStore: ServicesStore
  ) {}

  ngOnInit() {
    this.formService.formData$.next(servicesMenuData);
    this.listenFormChanges();

    this.formService.selectedStartDate.subscribe(
      date => (this.selectedStartDate = date)
    );
    this.formService.selectedEndDate.subscribe(
      date => (this.selectedEndDate = date)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFormGroupChange(_e) {
    this.serviceForm = _e;
    _e.patchValue(this.menuInitialData);

    _e.get('startDate').valueChanges.subscribe(date =>
      this.formService.selectedStartDate.next(date)
    );
    _e.get('endDate').valueChanges.subscribe(date =>
      this.formService.selectedEndDate.next(date)
    );
  }

  private listenFormChanges() {
    this.formService.servicesMenuData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterData: IServicesFilter) => {
        this.menuInitialData = filterData;
        this.jobsStore.getItems(filterData);
      });
  }
}
