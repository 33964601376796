import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pendingEvent',
})
export class PendingEventPipe implements PipeTransform {
  transform(value: unknown, user: string) {
    if (Array.isArray(value) && value) {
      const newValue = value.filter(
        item => item.status === 'pending' && item.creator === user
      );
      return newValue;
    } else return value;
  }
}
