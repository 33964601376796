import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import { interval, take, finalize, BehaviorSubject } from 'rxjs';
import { AdmobService } from 'src/app/shared/adv/admob.service';

@Component({
  selector: 'app-ad-modal',
  templateUrl: './ad-modal.component.html',
  styleUrls: ['./ad-modal.component.scss'],
})
export class AdModalComponent implements OnDestroy {
  @Input() isOpenAdModal;
  @Output() ok: EventEmitter<boolean> = new EventEmitter(false);
  @ViewChild('modal') modal: IonModal;

  timer;
  msg: string = null;
  showBanner = false;
  loading = new BehaviorSubject<boolean>(true);
  isCanClose = false;

  isMobile = this.admobService.isMobile;
  isMobileWeb = this.admobService.isMobileWeb;

  constructor(private router: Router, private admobService: AdmobService) {}

  handleClose() {
    this.ok.next(true);
    this.timer = null;
  }

  startTimer() {
    this.isCanClose = false;

    if (this.isOpenAdModal && !this.timer) {
      this.loading.next(true);
      this.timer = interval(1000)
        .pipe(
          take(5),
          finalize(() => {
            this.ok.next(true);
            this.isOpenAdModal = false;
            this.showBanner = false;
            this.msg = null;
            this.timer = null;
            this.isCanClose = true;
          })
        )
        .subscribe(async sec => {
          if (!this.showBanner) {
            this.showBanner = true;
            this.loading.next(false);
          }

          this.msg = sec === 3 ? `${4 - sec} second` : `${4 - sec} seconds`;
        });
    }
  }

  ngOnDestroy() {
    this.loading.next(true);
  }
}
