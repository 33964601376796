import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../firebase/auth.service';
@Component({
  selector: 'app-subscription-by-pearls-notification',
  templateUrl: './subscription-by-pearls-notification.component.html',
  styleUrls: ['./subscription-by-pearls-notification.component.scss'],
})
export class SubscriptionByPearlsNotificationComponent implements OnInit {
  @Input() subscriptionByPearlsPrice;
  @Input() isSubscriptionForPearlsModalOpen;
  @Input() subscriptionPeriod;
  @Output() subscriptionByPearlsPriceChange = new EventEmitter<any>();
  @Output() isSubscriptionForPearlsModalOpenChange = new EventEmitter<any>();
  @Output() getInfoHandler = new EventEmitter<any>();
  properties;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    mixpanel.init(environment.mixpanelToken, { debug: true });
    const { uid } = this.authService.isUserAuth();
    mixpanel.identify(uid);

    this.properties = {
      content: this.subscriptionPeriod,
      'pearls-spent': 'Pearls',
      amount: this.subscriptionByPearlsPrice,
    };
    mixpanel.track('Pearls Spent', this.properties);
  }

  closeSubscriptionForPearlsNotification() {
    this.isSubscriptionForPearlsModalOpenChange.next(false);
    this.subscriptionByPearlsPriceChange.next(null);

    setTimeout(() => {
      this.router.navigate(['/?notification=shown']);
      this.getInfoHandler.emit();
    }, 0);
  }
}
