import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { filter, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { AuthService } from '../../../firebase/auth.service';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements OnInit {
  @Input() isOpenSwiper;
  @Input() isCreator;

  @Input() images;
  @Input() userId: string;
  @Input() config: SwiperOptions;
  @Output() isClose = new EventEmitter<boolean>(false);
  @Output() isRemoveImg = new EventEmitter<boolean>(false);
  user;
  isContentFlaggedModal: boolean = false;
  isOpenFlagModal: boolean = false;
  flagData;
  emitterValue = false;
  currentUserId: string = this.activeRouter.snapshot.params.id;
  currentFeedItem;

  private destroy$ = new Subject<void>();
  constructor(
    private authService: AuthService,
    private activeRouter: ActivatedRoute,
    private fireStore: AngularFirestore,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  closeModal() {
    this.emitterValue = !this.emitterValue;
    this.isClose.emit(false);
  }
  removeImg() {
    this.isRemoveImg.emit(true);
  }

  async flagContent(slide) {
    slide.userId = this.currentUserId;
    slide.type = 'galleryPhoto';

    this.flagData = slide;

    const functions = getFunctions();
    const isContentFlagged = httpsCallable(functions, 'isContentFlagged');
    const res = await isContentFlagged({
      itemId: slide.userId,
      url: slide.url,
      type: this.flagData.type,
    });
    if (res.data === true) {
      this.isContentFlaggedModal = true;
    } else {
      this.isOpenFlagModal = true;
    }
  }

  viewPostButton(currentImage: any) {
    this.fireStore
      .collection('activityFeed', ref =>
        ref
          .where('feedType', '==', 'userPhoto')
          .where('userId', '==', this.currentUserId)
      )
      .get()
      .subscribe(res => {
        res.docs.forEach(item => {
          (item.data() as any).images.forEach(image => {
            if (image.url === currentImage.url) {
              this.currentFeedItem = item.data() as any;
              setTimeout(() => {
                this.router.navigate([
                  '/media/' + this.currentFeedItem.feedItemId,
                ]);
              }, 0);
            }
          });
        });
      });

    this.closeModal();
  }

  private getUser() {
    this.authService
      .getUserByUid(this.userId)
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user)
      )
      .subscribe(user => {
        this.user = user;
      });
  }
}
