import { documentId } from 'firebase/firestore';
import { CrudHelper } from '../../firebase/helpers/crudHelper';

export abstract class CommonService {
  private _firebaseHelperService: CrudHelper;

  protected constructor(firebaseHelperService: CrudHelper) {
    this._firebaseHelperService = firebaseHelperService;
  }

  getItemByValue({ collectionName, searchField }) {
    const queryFn = ref => {
      if (!searchField) {
        return ref;
      }

      let query = ref;

      const [fieldName, fieldValue] = Object.entries(searchField)[0];

      if (fieldName === 'documentId') {
        return query.where(documentId(), '==', fieldValue);
      } else {
        return ref.where(fieldName, '==', fieldValue);
      }
    };
    return this._firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  getItems({ collectionName, filter }) {
    const queryFn = null;
    return this._firebaseHelperService.getCollectionItems({
      collectionName,
      queryFn,
    });
  }

  addItem({ collectionName, data }) {
    return this._firebaseHelperService.createDocument({ collectionName, data });
  }

  updateItemById(collectionName: string, item: any, id: string) {
    return this._firebaseHelperService.updateItem({
      data: item,
      id,
      collectionName,
    });
  }

  deleteItem({ collectionName, id }) {
    return this._firebaseHelperService.deleteHelper({ collectionName, id });
  }

  getItemsCount(collectionName, userId, forbidden) {
    return this._firebaseHelperService.getCountItems({
      collectionName,
      userId,
      forbidden,
    });
  }

  getPreviewItems(collectionName, userId, limit, forbidden) {
    return this._firebaseHelperService.getPreviewItems({
      collectionName,
      userId,
      limit: limit,
      forbidden,
    });
  }

  getFirstPaginatedItems = (collectionName, userId, limit, forbidden) => {
    return this._firebaseHelperService.getFirstPaginatedItems({
      collectionName,
      userId,
      limit,
      forbidden,
    });
  };

  getFirstPagePaginatedItems = (
    collectionName,

    queryFn,
    limit,
    sortBy
  ) => {
    return this._firebaseHelperService.getFirstPagePaginatedItems({
      collectionName,
      queryFn,
      limit,
      sortBy,
    });
  };

  getNextPaginatedItems = (
    collectionName,
    userId,
    limit,
    lastInResponse,
    forbidden
  ) => {
    return this._firebaseHelperService.getNextPaginatedItems({
      collectionName,
      userId,
      limit,
      lastInResponse,
      forbidden,
    });
  };

  getLastPaginatedItems(collectionName, userId, limit, forbidden) {
    return this._firebaseHelperService.getLastPaginatedItems({
      collectionName,
      userId,
      limit,
      forbidden,
    });
  }

  checkDate(startDate, endDate) {
    if (startDate && endDate) {
      const startDay = new Date(startDate.seconds * 1000).getDate();
      const endDay = new Date(endDate.seconds * 1000).getDate();
      return startDay === endDay;
    }
  }
}
