import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WonderPush } from '@awesome-cordova-plugins/wonderpush/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicSelectableModule } from 'ionic-selectable';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileComponent } from './profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { GalleryComponent } from './gallery/gallery.component';
import { ProfileItemsComponent } from './items-component/items.component';
import { PipesModule } from '../pipes/pipes.module';
import { ProfilePictureUploaderComponent } from './profile-picture-uploader/profile-picture-uploader/profile-picture-uploader.component';
import { SharedModule } from '../shared/shared.module';
import { BazaarModule } from '../bazaar/bazaar.module';
import { JobsServicesModule } from '../bazaar/jobs-services/jobs-services.module';
import { EventsModule } from '../events/events.module';
import { PodsPageModule } from '../merfolk/pods/pods/pods.module';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { BioComponent } from './bio/bio.component';
import { FollowTabsComponent } from './follow-tabs/follow-tabs.component';
import { FollowListItemComponent } from './follow-list-item/follow-list-item.component';
import { FollowersListComponent } from './followers-list/followers-list.component';
import { FollowingListComponent } from './following-list/following-list.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { BlockListComponent } from './block-list/block-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ProfileRoutingModule,
    PipesModule,
    IonicSelectableModule,
    ImageCropperModule,
    SharedModule,
    BazaarModule,
    JobsServicesModule,
    PodsPageModule,
    EventsModule,
  ],
  providers: [WonderPush],
  declarations: [
    ProfileComponent,
    EditProfileComponent,
    GalleryComponent,
    ProfileItemsComponent,
    ProfilePictureUploaderComponent,
    UpdatePasswordComponent,
    BioComponent,
    FollowTabsComponent,
    FollowListItemComponent,
    FollowersListComponent,
    FollowingListComponent,
    NotificationSettingsComponent,
    BlockListComponent,
  ],
})
export class ProfileModule {}
